import React from 'react';
import PopularDestionationSection from './Sections/PopularDestionationSection';
import CuratedStories from './Sections/CuratedStories';

function Home() {
  return (
    <>
      <PopularDestionationSection />
      <CuratedStories />
    </>
  );
}

export { Home };
