import React from 'react';
import PropTypes from 'prop-types';

export function PageSubHeading({ children }) {
  return (
    <h2>
      {children}
    </h2>
  );
}

PageSubHeading.propTypes = {
  children: PropTypes.string.isRequired,
};
