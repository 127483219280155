export function reorderPlaylistByPlayStatus(playlist, viewedMedia) {
    // Filter unplayed media
    const unplayedMedia = playlist.filter((audio) => !viewedMedia.includes(audio.sectionID));
  
    // Filter played media and set 'played' attribute to true
    const playedMedia = playlist
      .filter((audio) => viewedMedia.includes(audio.sectionID))
      .map((audio) => ({ ...audio, hasPlayed: true })); // Add 'played' attribute set to true
  
    // Combine unplayed and modified played media lists
    const orderedPlaylist = [...unplayedMedia, ...playedMedia];
  
    return orderedPlaylist;
  }


export const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Provides smooth scrolling animation
    });
  };