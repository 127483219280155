// import Cart from './Cart/Cart.reducer'
// import Inventory from './Inventory/Inventory.reducer'
import player from './app/PlayerPage/player.reducer';
import profile from './app/profilePage/profile.reducer';   

const portalReducer = {
    // Cart,
    player,
    profile
}
export default portalReducer;
