import React from 'react';
import PropTypes from 'prop-types';
import { IconFactory, IconFactorySizes, IconFactoryTypes } from 'commons/Icons/IconFactory';

function InputContainer({ children }) {
  return (
    <div className="flex items-center justify-start gap-2 rounded-full text-white pl-4 py-2 font-semibold bg-gray-800 basis-80 ">
      {children}
    </div>
  );
}
InputContainer.propTypes = {
  children: PropTypes.element,
};
InputContainer.defaultProps = {
  children: null,
};

function SearchBar() {
  return (
    <InputContainer>
      <IconFactory iconName={IconFactoryTypes.SEARCH} iconSize={IconFactorySizes.smaller} />
      <input
        className="border-none outline-none p-0 bg-transparent placeholder-white basis-60 focus:ring-transparent"
        type="text"
        placeholder="Where are you going?"
      />
    </InputContainer>
  );
}

export default SearchBar;
