/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const cardTypeStyles = {
  small: 'relative shadow-3inn',
  medium: 'relative pb-4',
  large: 'flex flex-col border border-gray-300 w-60 rounded-lg ',
  fullSize: '',
};

function CardBoundary({ cardType, navigateTo, children }) {
  const navigate = useNavigate();

  // Function which navigate to define page on click of the component
  const handleCardClick = (event) => {
    event.preventDefault();
    if (navigateTo != null) {
      navigate(navigateTo);
    }
  };

  // Function to handle keyboard events
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleCardClick(event);
    }
  };

  return (
    <div
      className={`${cardTypeStyles[cardType]}`}
      onClick={handleCardClick}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      aria-label="Go back"
    >
      {children}
    </div>
  );
}
CardBoundary.propTypes = {
  cardType: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  navigateTo: PropTypes.string,
  children: PropTypes.element,
};
CardBoundary.defaultProps = {
  children: null,
  navigateTo: null,
};

export default CardBoundary;
