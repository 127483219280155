import React from 'react';
import PropTypes from 'prop-types';
import { IconFactory, IconFactoryColors, IconFactorySizes, IconFactoryTypes } from 'commons/Icons/IconFactory';

function CommentSection({ reviews }) {
  const { reviewCount } = reviews;
  return (
    <div className="flex items-center justify-start gap-2">
      <IconFactory iconName={IconFactoryTypes.COMMENT} iconSize={IconFactorySizes.smaller} color={IconFactoryColors.BLUE} />
      <span className="text-gray-300">{reviewCount}</span>
    </div>
  );
}
CommentSection.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      reviewCount: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export { CommentSection };
