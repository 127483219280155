export const PLAYER_ACTIONS = {
  SET_PLAYER_MEDIA: 'SET_PLAYER_MEDIA',
  SET_MORE_LIKE_THIS_PLAYLIST: 'SET_MORE_LIKE_THIS_PLAYLIST',
  RESET_PLAYER_STATE: 'RESET_PLAYER_STATE',
}

export function SetPlayerMediaAction(media) {
    return {
        type: PLAYER_ACTIONS.SET_PLAYER_MEDIA,
        payload: { 
            playerImageURL: media.sectionImg,
            playerAudioURL: media.sectionAudio,
            playerMediaId: media.sectionID,
            nowPlayingTitle: media.sectionName,
        },
    }
}

export function SetMoreLikeThisPlaylist(media) {
    return {
        type: PLAYER_ACTIONS.SET_MORE_LIKE_THIS_PLAYLIST,
        payload: {
            moreLikeThisPlaylist: media.moreLikeThis || [],
        },
    }
}

export const resetPlayerStateAction = () => ({
    type: PLAYER_ACTIONS.RESET_PLAYER_STATE,
});

