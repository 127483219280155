import React from 'react';
import { connect } from 'react-redux';
import { IconFactory, IconFactoryTypes } from 'commons/Icons/IconFactory';
import { APP_ROUTES } from 'configs/AppRoutes';
import { useNavigate } from 'react-router-dom';
import ButtonElement from './elements/buttonElement';
import { sayHiAction } from './profile.actions';

const profilePhoto = '/originalImgs/31s.jpg';

function ProfileComponent(props) {
  const routeNavigator = useNavigate();
  const { userName, sayHi } = props;
  return (
      <>
          <div className="my-4 flex items-center justify-between gap-6 ">
              <img
          className="h-24 w-24 max-w-none rounded-full border-4 border-solid border-blue-500"
          src={profilePhoto}
          alt="Profile Img"
        />
              <div className="flex flex-1 flex-col gap-2">
                  <h1 className="text-lg font-bold">{userName}</h1>
                  <div className="text-base font-bold cursor-pointer">
                      <span
              onClick={() => routeNavigator(APP_ROUTES.APP.CREDENTIALS.LOGIN_PAGE)}
            >
                          Login
                      </span>{' '}
                      /{' '}
                      <span
              onClick={() => routeNavigator(APP_ROUTES.APP.CREDENTIALS.SIGNUP_PAGE)}
            >
                          Sign up
                      </span>
                  </div>
                  <button  type="button" onClick={() => sayHi()}>Edit profile</button>
                  {/* <ButtonElement btnStyle="edit">
                      Edit Profile
                  </ButtonElement> */}
              </div>
          </div>

          <div className="flex flex-wrap justify-evenly">
              <div className="h-24 w-28 rounded-3xl bg-violet-300 p-4 ">
                  <h2 className="text-base font-semibold">My Storys</h2>
                  <div className="pt-3 text-2xl font-bold">100</div>
              </div>
              <div className="h-24 w-28 rounded-3xl bg-purple-300  p-4 ">
                  <h2 className="text-base font-semibold">Favourites</h2>
                  <div className="pt-3 text-2xl font-bold">100</div>
              </div>
              <div
          className="h-24 w-28 rounded-3xl bg-rose-400 p-4 "
          onClick={() => routeNavigator(APP_ROUTES.APP.CREATORS.NEW_STORY)}
        >
                  <h2 className="text-base font-semibold">Create Story</h2>
                  <IconFactory iconName={IconFactoryTypes.ARROW_FORWARD} />
              </div>
          </div>

          <div className="my-6 rounded-t-3xl">
              <div className="flex cursor-pointer items-center justify-evenly gap-4 py-6">
                  <IconFactory iconName={IconFactoryTypes.FILE} />
                  <p className="text-md flex-1 font-semibold">
                      Activities and Transactions
                  </p>
                  <IconFactory iconName={IconFactoryTypes.ARROW_FORWARD} />
              </div>
              <div className="flex cursor-pointer items-center justify-evenly gap-4 py-6">
                  <IconFactory iconName={IconFactoryTypes.PROFILE_EXCLAIMATION} />
                  <p className="text-md flex-1 font-semibold">Permissions</p>
                  <IconFactory iconName={IconFactoryTypes.ARROW_FORWARD} />
              </div>
              <div className="flex cursor-pointer items-center justify-evenly gap-4 py-6">
                  <IconFactory iconName={IconFactoryTypes.SETTINGS} />
                  <p className="text-md flex-1 font-semibold">Settings</p>
                  <IconFactory iconName={IconFactoryTypes.ARROW_FORWARD} />
              </div>
              <div className="flex cursor-pointer items-center justify-evenly gap-4 py-6">
                  <IconFactory iconName={IconFactoryTypes.MESSAGE} />
                  <p className="text-md flex-1 font-semibold">Contact Us</p>
                  <IconFactory iconName={IconFactoryTypes.ARROW_FORWARD} />
              </div>
              <div className="flex cursor-pointer items-center justify-evenly gap-4 py-6">
                  <IconFactory iconName={IconFactoryTypes.EXCLAIMATION} />
                  <p className="text-md flex-1 font-semibold">About Us</p>
                  <IconFactory iconName={IconFactoryTypes.ARROW_FORWARD} />
              </div>
          </div>

          <ButtonElement btnStyle="signOut" type="button">
              Sign Out
          </ButtonElement>
      </>
  );
}
export const mapStateToProps = (state) => {
  return {
    userName: state.profile.UserName || "Jon Doe",
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
      dispatch,
      sayHi: () => dispatch(sayHiAction())
  };
};

// Connect the component and export it
const Profile = connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
export { Profile };
