import React from 'react';
import PropTypes from 'prop-types';

function SubmitButton({ disabled }) {
  const activeBtnColor = 'bg-[#AB22C4]';
  const disabledBtnColor = 'bg-[#b3a8b5]';
  const backgroundColorClass = disabled ? disabledBtnColor : activeBtnColor;
  return (
    <button
      type="submit"
      disabled={disabled}
      className={`my-4 rounded-xl px-10 py-2 font-semibold text-white ${backgroundColorClass}`}
    >
      Continue
    </button>
  );
}

SubmitButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export { SubmitButton };
