import { MapElement } from 'commons/Map/MapElement';
import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import { detailsPageJSON } from '../catalogPages/detailsPage/detailsPage.data';
import { ListingPage } from './ListingPage/listingPage';
import './sectionPage.css';


const SectionPage = () => {
    const [isMapView, setIsMapView] = useState(false);

    return (
        <div className="section-page-container">
            <div className="audiobar">
                <div className="my-3 font-bold text-xl tracking-wider">
                    <h1>{detailsPageJSON.pageTitle}</h1>
                </div>
            </div>
            <div className="header">
                <div className="my-3 font-bold text-xl tracking-wider">
                    <h1>All Sections</h1>
                </div>
                <div className="switch">
                    <span>Map View</span>
                    <Switch
                        checked={isMapView}
                        onChange={() => setIsMapView(!isMapView)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>
            </div>
            {isMapView && <MapElement />}
            {!isMapView && <ListingPage pageData={detailsPageJSON} />}
        </div>
    );
};
export { SectionPage };
