import React from 'react';
import PropTypes from 'prop-types';
import { IconFactory, IconFactorySizes, IconFactoryTypes } from 'commons/Icons/IconFactory';
import CardBoundary from 'commons/Cards/elements/CardBoundary';
import { APP_ROUTES } from 'configs/AppRoutes';

function AvatarsSections({ pageSections }) {
  // from pageSections select only top 5 values if values are more than that other wise take whatever is there
  let selectedSections = [];
  const totalSectionCount = pageSections.length;
  let additionalSectionCount = null;
  if (pageSections.length >= 5) {
    selectedSections = pageSections.slice(0, 5);
    additionalSectionCount = totalSectionCount - 5;
  } else {
    selectedSections = pageSections;
  }

  return (
      <div className="flex items-center justify-start gap-4 pt-4">
          <IconFactory iconName={IconFactoryTypes.SECTION} iconSize={IconFactorySizes.smaller} />
          <div className="flex items-center justify-between gap-20" style={{width: '100%'}}>
              <span className="text-sm">Sections</span>

              <CardBoundary navigateTo={APP_ROUTES.APP.SUB_PAGES.SECTION_PAGE}>
                  <div className="flex -space-x-2 rtl:space-x-reverse">
                      {selectedSections.map((section) => (
                          <img
                loading="lazy"
                onError={() => { <IconFactory iconName={IconFactoryTypes.SECTION} iconSize={IconFactorySizes.smaller} />; }}
                className="h-10 w-10 rounded-full border-2 border-white"
                src={section.sectionImg}
                alt="..."
              />
            ))}
                      {additionalSectionCount && (
                      <div className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-700 bg-white text-xs font-medium text-black hover:bg-gray-600">
                          <span className="text-sm">+{additionalSectionCount}</span>
                      </div>
            )}
                  </div>
              </CardBoundary>
          </div>
      </div>
  );
}
AvatarsSections.propTypes = {
  pageSections: PropTypes.arrayOf(
    PropTypes.shape({
      sectionImg: PropTypes.element.isRequired,
    }),
  ).isRequired,
};

export { AvatarsSections };
