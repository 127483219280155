import React, { useId, useState } from 'react';
import { AccordionElement } from 'commons/Accordion/accordionElement';
import { NewSection } from './elements/NewSection';

const AddSections = () => {
  const [AddNewSection, setAddNewSection] = useState({});

  function CreateNewSection() {
    const newSectionId = Object.keys(AddNewSection).length + 1;
    setAddNewSection({
      ...AddNewSection,
      [newSectionId]: { id: newSectionId, sectionComponent: <NewSection /> },
    });
  }
  //   console.log('data of addnewsection using .key:', Object.keys(AddNewSection));
  //   console.log('data of addnewsection using .values:', Object.values(AddNewSection));
  //   console.log('data of addnewsection using .entries:', Object.entries(AddNewSection));

  return (
    <>
      {Object.entries(AddNewSection).map(([id, section]) => (
        <div key={id}>
          <AccordionElement
            sectionId={id}
            setAddNewSection={setAddNewSection}
            AddNewSection={AddNewSection}
            content={section.sectionComponent}
            title={` New Section ${id}`}
          />
        </div>
      ))}
      <button
        type="button"
        onClick={CreateNewSection}
        className="mx-auto my-5 flex rounded-lg bg-green-500 px-6 py-1 text-lg font-medium"
      >
        New Section
      </button>
    </>
  );
};

export { AddSections };
