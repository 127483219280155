// based on route this components will render
import React from 'react';
import { CarouselSection } from 'commons/carousel/carouselSection';
import { HighlightAndReviews } from './elements/HighlightAndReviews';
import { HeroContentWrapper } from './elements/HeroContentWrapper';
import {
  Duration,
  Languages,
} from './elements/activities';
import { TicketBanner } from './elements/ticketBanner';
import { detailsPageJSON } from './detailsPage.data';
import { MoreDetailsPage } from './elements/moreDetailsPage/moreDetailsPage';
import { AvatarsSections } from './elements/avatarsSection';
import ElectiveSectionsDetails from './elements/electiveSectionDetails';
// Lazy loading import
// const CarouselSection = lazy(() => import('commons/carousel/carouselSection'));

function DetailsPage() {
  const {
    primaryImgs,
    pageCatagory,
    ratings,
    userReviews,
    pageTitle,
    author,
    pageDescriptions,
    pageSections,
    languages,
    totalDuration,
    electiveSectionDetails,
    moreDetails,
  } = detailsPageJSON;

  return (
      <>
          <CarouselSection primaryImgs={primaryImgs} />
          <HighlightAndReviews
            pageCatagory={pageCatagory}
            rating={ratings}
            reviews={userReviews}
          />
          <HeroContentWrapper
            pageTitle={pageTitle}
            author={author}
            pageDescriptions={pageDescriptions}
          />
          <AvatarsSections pageSections={pageSections} />
          <Languages languages={languages} />
          <Duration duration={totalDuration} />
          <ElectiveSectionsDetails details={electiveSectionDetails} />
          <MoreDetailsPage moreDetails={moreDetails} />
          <TicketBanner />
      </>
  );
}

export default DetailsPage;
