import React from 'react';
import PropTypes from 'prop-types';
import HorizontalCardContainer from 'commons/CardsContainer/HorizontalCardContainer';
import { AllCategories } from './AllCategories';

const CategorieSection = ({ Categories, clickHandler }) => {
  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      clickHandler(e);
    }
  }

  return (
    <div className="my-5">
      <h2 className="text-lg font-bold tracking-wider">CATEGORIES</h2>

      <HorizontalCardContainer>
        <AllCategories handleClick={(categoryType, e) => clickHandler(categoryType, e)} />
        {Categories.map(({ categorieImg, categorieType }, index) => (
          <div
            key={index}
            className="flex min-w-20 flex-col items-center justify-center"
            onClick={(e) => clickHandler(categorieType, e)}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => handleKeyDown(e)}
          >
            <img
              className="size-20 rounded-full bg-cover"
              src={categorieImg}
              alt="category"
            />
            <div>{categorieType}</div>
          </div>
        ))}
      </HorizontalCardContainer>
    </div>
  );
};

CategorieSection.propTypes = {
  Categories: PropTypes.arrayOf(PropTypes.element).isRequired,
  clickHandler: PropTypes.func.isRequired,
};
export { CategorieSection };
