import React from 'react';
import PropTypes from 'prop-types';
import {
  IconFactory,
  IconFactoryColors,
  IconFactorySizes,
  IconFactoryTypes,
} from 'commons/Icons/IconFactory';

function ActivitiesWrapper({ children }) {
  return (
    <div className="flex items-center justify-start gap-4 pt-4">
      {children}
    </div>
  );
}
ActivitiesWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

function Activity({ icon, text, textTwo }) {
  return (
    <ActivitiesWrapper>
      {icon}
      <div className="flex flex-col justify-center gap-1">
        <span className="text-sm">{text}</span>
        <p className="text-xs text-gray-300">{textTwo}</p>
      </div>
    </ActivitiesWrapper>
  );
}

Activity.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  textTwo: PropTypes.string.isRequired,
};

function Languages({ languages }) {
  const { primaryLanguage, allLanguages } = languages;
  return (
    <Activity
      icon={(
        <IconFactory
          iconName={IconFactoryTypes.LANGUAGE}
          iconSize={IconFactorySizes.smaller}
        />
      )}
      text={primaryLanguage}
      textTwo={allLanguages}
    />
  );
}

Languages.propTypes = {
  languages: PropTypes.string.isRequired,
};

function Duration({ duration }) {
  return (
    <Activity
      icon={(
        <IconFactory
          iconName={IconFactoryTypes.TIME}
          iconSize={IconFactorySizes.smaller}
        />
      )}
      text={`Duration: ${duration}`}
    />
  );
}

Duration.propTypes = {
  duration: PropTypes.number.isRequired,
};

function TicketInclude() {
  return (
    <Activity
      icon={(
        <IconFactory
          iconName={IconFactoryTypes.TICKET}
          iconSize={IconFactorySizes.smaller}
          color={IconFactoryColors.GREEN}
        />
        )}
      text="Ticket Included"
    />
  );
}

TicketInclude.propTypes = {
  ticket: PropTypes.shape({
    isTicketIncluded: PropTypes.bool.isRequired,
  }).isRequired,
};

function WheelChair() {
  return (
    <Activity
      icon={(
        <IconFactory
          iconName={IconFactoryTypes.WHEELCHAIR}
          iconSize={IconFactorySizes.smaller}
          color={IconFactoryColors.GREEN}
        />
        )}
      text="Wheelchair Accessible"
    />
  );
}

WheelChair.propTypes = {
  wheelChair: PropTypes.shape({
    isWheelChairAccessible: PropTypes.bool.isRequired,
  }).isRequired,
};

export { Languages, Duration, WheelChair, TicketInclude };
