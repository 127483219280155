const linksArr1 = [
  { title: 'Company' },
  {
    text: 'About',
    textLink: '',
  },
  {
    text: 'Jobs',
    textLink: '',
  },
  {
    text: 'For the Records',
    textLink: '',
  },
];

const linksArr2 = [
  { title: 'Communities' },
  {
    text: 'For Artists',
    textLink: '',
  },
  {
    text: 'Developers',
    textLink: '',
  },
  {
    text: 'Advertising',
    textLink: '',
  },
  {
    text: 'Investors',
    textLink: '',
  },
  {
    text: 'Vendors',
    textLink: '',
  },
];

const linksArr3 = [
  { title: 'Useful links' },
  {
    text: 'Support',
    textLink: '',
  },
  {
    text: 'Web Player',
    textLink: '',
  },
  {
    text: 'Free Mobile App',
    textLink: '',
  },
];

export {
  linksArr1, linksArr2, linksArr3,
};
