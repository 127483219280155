import React from 'react';

const formImg = '/originalImgs/waitListFormImg.svg';

function FormImgComponent() {
  return (
    <div className="flex flex-col justify-center items-center gap-8 p-4">
      <img src={formImg} alt="form Img" className="w-[70%] mx-auto" />
      <h1 className="heroHeading text-6xl py-3">STORYMODE</h1>
    </div>
  );
}

export { FormImgComponent };
