import React, { useId, useState } from 'react';
import PropTypes from 'prop-types';
import { formElementStyle } from '../elements/formElementsStyle';

const { labelStyle, constantStyle, textAreaInput } = formElementStyle;

const TextAreaInput = ({ label, name, placeholder, required }) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const id = useId();

  function handleChange(e) {
    e.preventDefault();
    return setInputValue(e.target.value);
  }

  function handleBlur() {
    // e.preventDefault();
    return inputValue === '' ? setError('Please enter a value...') : setError('');
  }

  return (
    <label htmlFor={id} className={labelStyle}>
      <div>
        {label} {required && <span className={constantStyle}>*</span>}
      </div>
      <textarea
        name={name}
        id={id}
        className={textAreaInput}
        rows={4}
        cols={45}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        spellCheck
        required
      />
      <span className={constantStyle}>{error}</span>
    </label>
  );
};

TextAreaInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};
export { TextAreaInput };
