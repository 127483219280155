import React from 'react';
// import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const SkeletonWrapper = ({ children, otherStyles }) => (
  <div className={`animate-pulse flex flex-col justify-start gap-2 ${otherStyles}`}>
    {children}
  </div>
);

export { SkeletonWrapper };
