import { APP_ROUTES } from 'configs/AppRoutes';
import { RegisterPage } from './staticSections/preRegisterPage/registerPage';

const register = [
  {
    path: APP_ROUTES.LANDING_PAGE.JOIN_WAITLIST,
    View: RegisterPage,
  },
];

export { register };
