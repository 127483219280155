import { combineReducers, applyMiddleware, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import { thunk } from 'redux-thunk';
import createDebounce from 'redux-debounce';
import portalReducer from './portal.reducer';
// import commonReducer from './common/common.reducer';

export default () => {
  console.log(portalReducer);
  const allReducers = { ...portalReducer };
  const rootReducer = combineReducers(allReducers);
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => 
      getDefaultMiddleware().concat(createDebounce(), thunk, logger),
    devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
  });
  return store;
};
