import React from 'react';
import PropTypes from 'prop-types';

const MutlipleOptionInput = (props) => {
  const { selectedValues, setSelectedValues } = props;

  const handleDelete = (valueToDelete) => {
    let newSelectedValues = [...selectedValues];
    newSelectedValues = newSelectedValues.filter((value) => value !== valueToDelete);
    setSelectedValues(newSelectedValues);
  };

  return (
    <div className="no-scrollbar mb-1 overflow-x-auto overflow-y-hidden rounded-2xl h-10 border px-3 pr-4 py-2">
      <div className="flex gap-1">
        {selectedValues.map((value, index) => (
          <p key={index} className=" flex items-center gap-1 rounded-md bg-[#2E2E2E] pl-2 text-md">
            {value}
            <button type="button" key={index} onClick={() => (handleDelete(value))} className="bg-red-500 px-2 rounded-md">X
            </button>
          </p>
        ))}
      </div>
    </div>
  );
};

MutlipleOptionInput.propTypes = {
  selectedValues: PropTypes.string.isRequired,
  setSelectedValues: PropTypes.string.isRequired,
};

export { MutlipleOptionInput };
