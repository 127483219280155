import React from 'react';
import PropTypes from 'prop-types';

function Catagorys({ pageCatagory }) {
  return (
    <div className=" text-sm font-bold">{pageCatagory}</div>
  );
}

Catagorys.propTypes = {
  pageCatagory: PropTypes.string,
};
Catagorys.defaultProps = {
  pageCatagory: 'GUIDE',
};

export { Catagorys };
