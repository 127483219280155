import { APP_ROUTES } from 'configs/AppRoutes';
import { catalogPageRoutes } from './catalogPages/catalogPages.routes';
import { DestinationPage } from './DestinationPages/destinationPage';
import { SectionPage } from './SectionsPage/sectionPage';

const subPagesRoutes = [
  ...catalogPageRoutes,
  {
    path: APP_ROUTES.APP.SUB_PAGES.DESTINATION_PAGE,
    View: DestinationPage,
  },
  {
    path: APP_ROUTES.APP.SUB_PAGES.SECTION_PAGE,
    View: SectionPage,
  },
];

export {
  subPagesRoutes,
};
