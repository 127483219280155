import React from 'react';
import './querys.css';

const queryImg = '/originalImgs/removedBgImgs/query_img.png';

function Querys() {
  return (
    <div className="queryContainer">
      <div className="queryFlex">
        <img src={queryImg} alt="query Img" />
        <h2>
          Imagine standing at a historic landmark or a bustling street corner,
          and with a simple tap, you can listen to countless voices sharing
          their stories, insights, and secrets about that very spot. It&apos;s
          like having your own personal tour guide at your fingertips, ready to
          enrich your connection to the world around you.
        </h2>
      </div>
    </div>
  );
}

export default Querys;
