import React, { useState, useId } from 'react';
import PropTypes from 'prop-types';
import { IconFactory, IconFactorySizes, IconFactoryTypes } from 'commons/Icons/IconFactory';
import { formElementStyle } from '../elements/formElementsStyle';
import { TypeFileElement } from './elements/typeFileElement';

const { labelStyle, constantStyle, fileInputStyle } = formElementStyle;

const InputTypeFiles = ({ label, type, required, accept, onfileSelected, multiple }) => {
  const [inputError, setInputError] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const id = useId();

  function handleChange(e) {
    e.preventDefault();
    const filesArray = Array.from(e.target.files);
    setSelectedFiles(filesArray);
    onfileSelected(filesArray);
    // console.log(typeof onfileSelected);
  }
  //   console.log('this is Selected files', selectedFiles);

  function handleBlur() {
    return selectedFiles === ''
      ? setInputError('Please select a file')
      : setInputError('');
  }

  return (
    <>
      <label htmlFor={id} className={labelStyle}>
        <div>
          {label} {required && <span className={constantStyle}>*</span>}
        </div>
        <div className="px-6 py-2 bg-purple-700 font-medium rounded-xl text-white flex justify-center gap-4 items-center">
          <IconFactory iconName={IconFactoryTypes.UPLOAD_FILES} iconSize={IconFactorySizes.small} />
          Add Files
          <input
            id={id}
            type={type}
            className={fileInputStyle}
            onChange={handleChange}
            onBlur={handleBlur}
            accept={accept}
            multiple={multiple}
            required
          />
        </div>
        {inputError && <p className={`${constantStyle}`}>{inputError}</p>}
      </label>
      {({ selectedFiles }) && <TypeFileElement selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />}
    </>
  );
};

InputTypeFiles.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
  onfileSelected: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
};

export { InputTypeFiles };
