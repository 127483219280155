import React from 'react';
import PropTypes from 'prop-types';
import { SkeletonTypes } from './commons';

const skeletonStyles = {
  line: 'rounded-sm',
  circle: 'rounded-full',
  box: 'rounded-lg',
};

function BaseSkeleton({
  skeletonType, height, width, otherStyles,
}) {
  return <div className={`${skeletonStyles[skeletonType]} bg-[#dddddd] animate-pulse ${height} ${width} ${otherStyles}`} />;
}

BaseSkeleton.propTypes = {
  skeletonType: PropTypes.oneOf(Object.values(SkeletonTypes)).isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  otherStyles: PropTypes.string,
};

BaseSkeleton.defaultProps = {
  otherStyles: '',
};
export { BaseSkeleton };
