/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';

const NewSectionButton = ({ sectionName, imageFileHandler, audioFileHandler, setSectionDetails, SectionDetails }) => {
  function handleSave(event) {
    event.preventDefault();
    const sectionData = {
      SectionName: sectionName,
      SectionImages: imageFileHandler,
      SectionAudios: audioFileHandler,
    };
    setSectionDetails([sectionData]);
    console.log('Section Name:', sectionName);
    console.log('Section Images:', imageFileHandler);
    console.log('Section Audios:', audioFileHandler);
  }

  return (
    <div className="flex justify-evenly gap-3 my-5">

      <button
        type="submit"
        onClick={handleSave}
        className="mx-auto mt-4 flex w-40 items-center justify-center rounded-md bg-slate-200 font-semibold px-4 py-2 text-center text-black outline-none"
      >
        Save
      </button>
    </div>
  );
};

NewSectionButton.propTypes = {
  sectionName: PropTypes.string.isRequired,
  imageFileHandler: PropTypes.string.isRequired,
  audioFileHandler: PropTypes.string.isRequired,
  setSectionDetails: PropTypes.string.isRequired,
  SectionDetails: PropTypes.string.isRequired,
};

export { NewSectionButton };
