import React from 'react';
import PropTypes from 'prop-types';

function PortalContainer({ children }) {
  return (
    <div className="mx-auto min-h-lvh bg-black px-4 text-white w-full z-10 md:w-7/12 lg:w-4/6 xl:w-1/4 ">
      {children}
    </div>
  );
}
PortalContainer.propTypes = {
  children: PropTypes.element,
};
PortalContainer.defaultProps = {
  children: null,
};

export { PortalContainer };
