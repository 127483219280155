/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const headingTypes = {
  large: 'p-4 text-3xl',
  medium: 'p-3 text-2xl',
  small: 'p-2 text-xl',
};

export function PageHeading({ type, isBold, children }) {
  return (
    <h1 className={`${headingTypes[type]} ${isBold ? 'font-bold' : 'font-normal'}`}>
      {children}
    </h1>
  );
}
PageHeading.propTypes = {
  type: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  isBold: PropTypes.bool,
  children: PropTypes.any.isRequired,
};
PageHeading.defaultProps = {
  isBold: true,
};
