/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { AboutStoryPage } from './subPages/aboutStoryPage';
import { AddSections } from './subPages/AddSectionPages/addSections';

const NewStory = () => {
  const [pageIndex, setPageIndex] = useState(0);

  const handleDecrement = (e) => {
    e.preventDefault();
    setPageIndex(pageIndex - 1);
  };

  const handleIncrement = (e) => {
    e.preventDefault();
    setPageIndex(pageIndex + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Submitted');
  };

  const getPageContent = () => {
    switch (pageIndex) {
      case 0:
        return <AboutStoryPage />;
      case 1:
        return <AddSections />;
      case 2:
        return <div>This is content for page 2</div>;
      case 3:
        return <div>This is content for page 3</div>;
      default:
        return null; // Handle out-of-bounds cases or display default content
        // return <div>initial def state</div>;
    }
  };

  return (
    <form onSubmit={handleIncrement}>
      {getPageContent()}
      <div className="flex justify-evenly">
        {pageIndex > 0 && <button className="px-6 py-1 bg-red-500 text-lg font-medium m-4 rounded-lg" onClick={handleDecrement}>Back</button> }
        <button type="submit" className="px-6 py-1 bg-blue-500 text-lg font-medium m-4 rounded-lg"> Next</button>
      </div>
    </form>
  );
};

export { NewStory };
