import { DropdownInput } from 'commons/formInputs/multiOptionsInput/dropDownInput';
import { TextAreaInput } from 'commons/formInputs/TextAreaInput/textAreaInput';
import { NewFormInput } from 'commons/formInputs/TextInput/NewTextInput';
import React from 'react';

const AboutStoryPage = () => (
  <div className="flex flex-col gap-4 pt-6">
    <DropdownInput
      name="Type Of Story"
      label="Type of Story"
      dropDownOptions={[
        'Select an input',
        'Guided Tour',
        'Water Activity',
        'Adventure',
      ]}
      required
    />
    <NewFormInput
      name="Title"
      type="text"
      inputMode="text"
      placeholder="Write your Title..."
      label="Title"
      onTextInputChange={() => { ''; }}
      required
    />
    <TextAreaInput
      name="About"
      placeholder="Write your About..."
      label="About"
      required
    />
    <DropdownInput
      label="Languages"
      name="Languages"
      dropDownOptions={[
        'Select any languages',
        'Hindi',
        'English',
        'Gujrati',
        'Kannada',
        'Punjabi',
        'Marathi',
      ]}
      multipleOptions
      required
    />
  </div>
);

export { AboutStoryPage };
