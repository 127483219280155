import { NewFormInput } from 'commons/formInputs/TextInput/NewTextInput';
import { InputTypeFiles } from 'commons/formInputs/typeFileInput/inputTypefiles';
import React, { useState, useCallback } from 'react';
import { NewSectionButton } from './newSectionButton';

const NewSection = () => {
  const [imageFileHandler, setImageFileHandler] = useState([]);
  const [audioFileHandler, setAudioFileHandler] = useState([]);
  const [sectionName, setSectionName] = useState('');
  const [SectionDetails, setSectionDetails] = useState([]);

  const handleSectionInput = useCallback(
    (inputText) => setSectionName(inputText),
    [],
  );
  const handleImageSelected = useCallback(
    (images) => setImageFileHandler(images),
    [],
  );
  const handleAudioSelected = useCallback(
    (audios) => setAudioFileHandler(audios),
    [],
  );
  return (
    <>
      <NewFormInput
        label="Section Name"
        type="Text"
        name="Section Name"
        placeholder="Enter the Name"
        onTextInputChange={handleSectionInput}
      />
      <InputTypeFiles
        label="Section Images"
        type="file"
        accept="image/*"
        onfileSelected={handleImageSelected}
        multiple
      />
      <InputTypeFiles
        label="Section Audios"
        type="file"
        accept="audio/*"
        onfileSelected={handleAudioSelected}
        multiple
      />
      <NewSectionButton
        SectionDetails={SectionDetails}
        setSectionDetails={setSectionDetails}
        sectionName={sectionName}
        imageFileHandler={imageFileHandler}
        audioFileHandler={audioFileHandler}
      />
    </>
  );
};

export { NewSection };
