import React from 'react';
import { PageHeading } from 'commons/PageElements/PageHeadings';
import HorizontalCardContainer from 'commons/CardsContainer/HorizontalCardContainer';
import useAxios from 'axios-hooks';
import { API_ENDPOINTS } from 'configs/APIEndpoints/index';
import { TitleCard } from 'commons/Cards/smallCards/titleCards';
import { APP_ROUTES } from 'configs/AppRoutes';

function PopularDestionationSection() {
  // eslint-disable-next-line no-unused-vars
  const [{ data: cityData, loading, error }] = useAxios(API_ENDPOINTS.LANDING_POPULARCITIES);

  if (error) return <div>error</div>;
  return (
    <>
      <PageHeading type="medium">Most popular destinations</PageHeading>
      <HorizontalCardContainer>
        {
            cityData?.map((city, i) => (
              <TitleCard key={i} imgURL={city.imgURL} cardTitle={city.name} navigateTo={APP_ROUTES.APP.SUB_PAGES.DESTINATION_PAGE} />
            ))
        }
      </HorizontalCardContainer>
    </>
  );
}

export default PopularDestionationSection;
