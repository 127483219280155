import React, { useState } from 'react';
import { NewFormInput } from 'commons/formInputs/TextInput/NewTextInput';
import { DropdownInput } from 'commons/formInputs/multiOptionsInput/dropDownInput';
import { SubmitButton } from './submitButton';
// import { RegisterPageConstants } from '../constantsregisterPage';

const dropDownOptions = ['Individual', 'Influencer', 'Institution', 'Anonymous'];

function RegistrationForm() {
  const [formError, setFormError] = useState();

  const formHandler = (error) => {
    setFormError((currentValue) => (currentValue || error));

    console.log('insiede form handler', error);
    console.log('form error', formError);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('handleSubmit', event);
    const formElements = Array.from(event.target.elements); // Convert HTMLCollection to array
    const formData = {};

    formElements.forEach((element) => {
      if (element.name) { // Check if the element has a name attribute
        formData[element.name] = element.value;
      }
    });

    // Log the extracted form values
    console.log('Form values:', formData);
    console.log('Form error:', formError);
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col justify-center gap-2 text-left"
    >
      <NewFormInput
        name="userName"
        type="text"
        label="Username"
        placeholder="Enter your Username"
        formHandler={formHandler}
        onTextInputChange={() => { ''; }}
        required
      />

      <NewFormInput
        name="Email"
        type="email"
        label="Email"
        placeholder="Username@example.com"
        formHandler={formHandler}
        onTextInputChange={() => { ''; }}
        required
      />

      <NewFormInput
        name="Phone"
        type="tel"
        label="Phone"
        formHandler={formHandler}
        placeholder="123-456-7890"
        onTextInputChange={() => { ''; }}
        required={false}
      />

      <DropdownInput
        name="Type"
        label="Type"
        dropDownOptions={dropDownOptions}
        formHandler={formHandler}
        required
      />
      <SubmitButton disabled={formError} />
    </form>
  );
}

export { RegistrationForm };
