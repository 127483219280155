/* eslint-disable max-len */
import React from 'react';
import './slogan1.css';

function TextDiv() {
  return (
    <div className="textContainer">
      <p>Revolutionize city exploration with StoryMode.</p>
      <p>Welcome to a new way of exploration! With StoryMode, we bring you a treasure trove of local contents, all in one place.</p>
    </div>
  );
}

export default TextDiv;
