import React from 'react';
import PropTypes from 'prop-types';
import { IoArrowBack, IoSettingsSharp, IoTime, IoCloudUploadSharp } from 'react-icons/io5';
import { HiMiniHome } from 'react-icons/hi2';
import { BiSolidSearch, BiSolidUser } from 'react-icons/bi';
import { MdError, MdChat, MdCancel, MdDeleteForever, MdLocationOn } from 'react-icons/md';
import { FaRegHeart, FaRegBell, FaCommentDots, FaStumbleuponCircle, FaBackward, FaForward } from 'react-icons/fa';
import { IoMdShare, IoIosArrowForward, IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { RiFileList3Fill, RiLoader4Line } from 'react-icons/ri';
import { TiStarFullOutline } from 'react-icons/ti';
import { LuLanguages } from 'react-icons/lu';
import { BsTicketPerforatedFill,
  BsPersonFillExclamation,
  BsExclamationCircleFill,
} from 'react-icons/bs';
import { FaWheelchair, FaCirclePlay, FaCirclePause, FaForwardStep, FaBackwardStep } from 'react-icons/fa6';
import { BsCollectionPlayFill } from "react-icons/bs";

export const IconFactorySizes = {
  smaller: 'w-5 h-5',
  small: 'w-6 h-6',
  large: 'w-7 h-7',
};

export const IconFactoryTypes = {
  BACK_BUTTON: 'BACK_BUTTON',
  SEARCH: 'SEARCH',
  HOME: 'HOME',
  PROFILE: 'PROFILE',
  ERROR: 'ERROR',
  LIKE: 'LIKE',
  NOTIFICATION: 'NOTIFICATION',
  SHARE: 'SHARE',
  FILE: 'FILE',
  ARROW_FORWARD: 'ARROW_FORWARD',
  PROFILE_EXCLAIMATION: 'PROFILE_EXCLAIMATION',
  SETTINGS: 'SETTINGS',
  MESSAGE: 'MESSAGE',
  EXCLAIMATION: 'EXCLAIMATION',
  COMMENT: 'COMMENT',
  RATING_STAR: 'RATING_STAR',
  SECTION: 'SECTION',
  LANGUAGE: 'LANGUAGE',
  TIME: 'TIME',
  TICKET: 'TICKET',
  WHEELCHAIR: 'WHEELCHAIR',
  CANCEL: 'CANCEL',
  UPLOAD_FILES: 'UPLOAD_FILES',
  DOWN_ARROW: 'DOWN_ARROW',
  UP_ARROW: 'UP_ARROW',
  DELETE: 'DELETE',
  LANDMARK: 'LANDMARK',
  PLAY_BUTTON: 'PLAY_BUTTON',
  PAUSE_BUTTON: 'PAUSE_BUTTON',
  TRACK_FORWARD: 'FORWARD_TRACK',
  TRACK_BACKWARD: 'TRACK_BACKWARD',
  NEXT_TRACK: 'NEXT_TRACK',
  PREVIOUS_TRACK: 'PREVIOUS_TRACK',
  LOADER: 'LOADER',
  PLAYER: 'PLAYER',
};

export const IconFactoryColors = {
  BLUE: 'text-blue-500',
  GOLDEN: 'text-yellow-400',
  GREEN: 'text-green-500',
  RED: 'text-red-400',
  LOADING: 'animate-spin',
  DEFAULT: '',
};

export const IconFactory = ({ iconName, iconSize, iconColor }) => {
  switch (iconName) {
    case IconFactoryTypes.BACK_BUTTON:
      return <IoArrowBack className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.SEARCH:
      return <BiSolidSearch className={`${iconSize}`} />;
    case IconFactoryTypes.HOME:
      return <HiMiniHome className={`${iconSize}`} />;
    case IconFactoryTypes.PROFILE:
      return <BiSolidUser className={`${iconSize}`} />;
    case IconFactoryTypes.ERROR:
      return <MdError className={`${iconSize}`} />;
    case IconFactoryTypes.LIKE:
      return <FaRegHeart className={`${iconSize}`} />;
    case IconFactoryTypes.NOTIFICATION:
      return <FaRegBell className={`${iconSize}`} />;
    case IconFactoryTypes.SHARE:
      return <IoMdShare className={`${iconSize}`} />;
    case IconFactoryTypes.FILE:
      return <RiFileList3Fill className={`${iconSize}`} />;
    case IconFactoryTypes.ARROW_FORWARD:
      return <IoIosArrowForward className={`${iconSize}`} />;
    case IconFactoryTypes.PROFILE_EXCLAIMATION:
      return <BsPersonFillExclamation className={`${iconSize}`} />;
    case IconFactoryTypes.SETTINGS:
      return <IoSettingsSharp className={`${iconSize}`} />;
    case IconFactoryTypes.MESSAGE:
      return <MdChat className={`${iconSize}`} />;
    case IconFactoryTypes.EXCLAIMATION:
      return <BsExclamationCircleFill className={`${iconSize}`} />;
    case IconFactoryTypes.COMMENT:
      return <FaCommentDots className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.RATING_STAR:
      return <TiStarFullOutline className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.SECTION:
      return <FaStumbleuponCircle className={`${iconSize}`} />;
    case IconFactoryTypes.LANGUAGE:
      return <LuLanguages className={`${iconSize}`} />;
    case IconFactoryTypes.TIME:
      return <IoTime className={`${iconSize}`} />;
    case IconFactoryTypes.TICKET:
      return <BsTicketPerforatedFill className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.WHEELCHAIR:
      return <FaWheelchair className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.CANCEL:
      return <MdCancel className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.UPLOAD_FILES:
      return <IoCloudUploadSharp className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.DOWN_ARROW:
      return <IoIosArrowDown className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.UP_ARROW:
      return <IoIosArrowUp className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.DELETE:
      return <MdDeleteForever className={`${iconSize} ${iconColor} hover:text-red-500`} />;
    case IconFactoryTypes.LANDMARK:
      return <MdLocationOn className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.PLAY_BUTTON:
      return <FaCirclePlay className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.PAUSE_BUTTON:
      return <FaCirclePause className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.TRACK_FORWARD:
      return <FaForward className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.TRACK_BACKWARD:
      return <FaBackward className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.NEXT_TRACK:
      return <FaForwardStep className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.PREVIOUS_TRACK:
      return <FaBackwardStep className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.LOADER:
      return <RiLoader4Line className={`${iconSize} ${iconColor}`} />;
    case IconFactoryTypes.PLAYER:
      return <BsCollectionPlayFill className={`${iconSize} ${iconColor}`} />;
    default:
      return <MdError className={`${iconSize}`} />;
  }
};

IconFactory.propTypes = {
  iconName: PropTypes.oneOf(Object.values(IconFactoryTypes)).isRequired,
  iconSize: PropTypes.oneOf(Object.values(IconFactorySizes)),
  iconColor: PropTypes.string,
};

IconFactory.defaultProps = {
  iconSize: IconFactorySizes.small,
  iconColor: IconFactoryColors.DEFAULT,
};
