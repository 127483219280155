import React from 'react';
import PropTypes from 'prop-types';

function CardBody({ children }) {
  return (
    <div className="flex flex-col justify-between w-60 gap-4 p-2 text-base">
      {children}
    </div>
  );
}
CardBody.propTypes = {
  children: PropTypes.element,
};
CardBody.defaultProps = {
  children: null,
};

function Wrapper({ children }) {
  return (
    <div className="font-bold">
      { children }
    </div>
  );
}

Wrapper.propTypes = {
  children: PropTypes.element,
};
Wrapper.defaultProps = {
  children: null,
};

function CardTitle({ title }) {
  return <p className="whitespace-normal">{title}</p>;
}
CardTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

function PriceSection({ price }) {
  return (
    price && (
      <p className="font-bold">
        From ₹ {price} <small>per person</small>
      </p>
    )
  );
}

PriceSection.propTypes = {
  price: PropTypes.number.isRequired,
};

function CardHeadingChip({ chipText }) {
  return <h4 className="uppercase text-gray-300">{chipText}</h4>;
}
CardHeadingChip.propTypes = {
  chipText: PropTypes.string,
};
CardHeadingChip.defaultProps = {
  chipText: null,
};

export {
  CardBody,
  CardTitle,
  CardHeadingChip,
  PriceSection,
  Wrapper,
};
