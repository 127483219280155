import { APP_ROUTES } from 'configs/AppRoutes';
import { register } from 'components/StaticSite/landingPage.routes';
import { AppLanding } from './app/LandingPage/LandingPage';
import { AudioPlayer } from './app/PlayerPage/Player';
import { Home } from './app/homePage/home';
import { Profile } from './app/profilePage/profile';
import { NewStory } from './app/CreatorPages/NewStory/NewStory';
import { subPagesRoutes } from './app/subPages/subPages.routes';

const portalRoutes = [
  {
    path: APP_ROUTES.APP.PLAYER_PATH,
    View: AudioPlayer,
  },
  {
    path: APP_ROUTES.APP.HOME_PATH,
    View: Home,
  },
  {
    path: APP_ROUTES.APP.PROFILE_PATH,
    View: Profile,
  },
  {
    path: APP_ROUTES.APP.CREATORS.NEW_STORY,
    View: NewStory,
  },
  { // remove later
    path: 'app/landing',
    View: AppLanding,
  },
];

const appRoutes = [
  {
    path: APP_ROUTES.BASE_PATH,
    View: Home,
  },
  ...portalRoutes,
  ...subPagesRoutes,
  ...register,
];

export {
  appRoutes,
};
