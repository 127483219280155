import devEndpoints from './endpoints.dev';
import prodEndpoints from './endpoints.prod';

const getConfig = () => {
  switch (process.env.NODE_ENV) {
    case 'production' || 'prod':
      return prodEndpoints;
    case 'development' || 'dev':
      return devEndpoints;
    default:
      return devEndpoints;
  }
};

export const API_ENDPOINTS = getConfig();
