import React from "react";
import CardBoundary from "commons/Cards/elements/CardBoundary";

const ListingPage = ({ pageData }) => {
  return (
      <div className="container">
          <div className="my-5">
              {pageData.pageSections.map((section, index) => (
                  <CardBoundary navigateTo="">
                      <div
                        key={index}
                        className="my-2 h-32 flex items-start justify-between gap-4 rounded-2xl border p-2"
                      >
                          <img
                            src={section.sectionImg}
                            className="size-32 h-full rounded-xl"
                            alt="destionation View"
                          />
                          <div className="flex flex-1 h-full flex-col items-start justify-around">
                              <h2 className="font-bold uppercase">{section.sectionName}</h2>
                              <p className="line-clamp-3 text-sm">
                                  {section.sectionInfo}
                              </p>
                          </div>
                      </div>
                  </CardBoundary>
              ))}
          </div>
      </div>
  );
};

export { ListingPage };
