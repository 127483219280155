import React from 'react';
import PropTypes from 'prop-types';

function HeroContentWrapper({ pageTitle, author, pageDescriptions }) {
  return (
    <div className="pt-3">
      <h1 className="pb-1 font-bold text-xl leading-6 tracking-tight ">{pageTitle}</h1>
      <span className="text-sm py-4">Author : <a href="/" className="text-blue-500 font-bold">{author.authorName}</a></span>
      <h1 className="mt-3 text-xl font-extrabold">About</h1>
      <p className="pt-4">
        {pageDescriptions}
      </p>
    </div>
  );
}
HeroContentWrapper.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  pageDescriptions: PropTypes.string.isRequired,
};

export { HeroContentWrapper };
