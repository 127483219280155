import React, { useState, useEffect } from 'react';
import { DestinationPageJSON } from './destinationPage.data';
import { CitySection } from './elements/CitySection';
import { DestinationList } from './elements/destinationList';
import { CategorieSection } from './elements/CategorieSection';

const DestinationPage = () => {
  const { Categories, destinationList } = DestinationPageJSON;
  const [Destination, setDestination] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // runs of 1st render of this component and whenver value of "selectedCategory" change
  useEffect(() => {
    if (selectedCategory === null || selectedCategory === 'All Categories') {
      setDestination(destinationList);
    } else {
      const filterDestination = destinationList.filter(
        (allDestinations) => selectedCategory === allDestinations.category,
      );
      setDestination(filterDestination);
    }
  }, [selectedCategory]);

  return (
      <div>
          <CitySection />
          <CategorieSection
          Categories={Categories}
          clickHandler={(category) => setSelectedCategory(category)}
          />
          <DestinationList DestinationsList={Destination} />
      </div>
  );
};

export { DestinationPage };
