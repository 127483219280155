export const detailsPageJSON = {
  primaryImgs: ['https://encrypted-tbn0.gstatic.com/licensed-image?q=tbn:ANd9GcTjOQAYqwVOW5N1y8kIk9G8NXUVCjmqxnR0yYWxxA3A4ONpK5H_6fKjJoSiC1kI_UdWCyjlWiuYKwssdT_HajWvJScaCgtpOnSvtrD3_A', 'https://encrypted-tbn2.gstatic.com/licensed-image?q=tbn:ANd9GcTtlVUoPtOqIYFk0Iyh4BAbS1G0yid1tNKz_x75E6aPP7K6LMdD-YiKQ2PyrDzMKkkhBU-mqDpTxbt0iujGXLqPnXVSH8F1qijHgN8nHA', 'https://t3.ftcdn.net/jpg/02/98/61/52/360_F_298615208_UaCHnCW2p9y8rHhcWKJDHaQDF4wqB7LV.jpg'],
  pageCatagory: 'GUIDE',
  ratings: 4.3,
  userReviews: {
    reviewCount: 40,
    reviews: ['Great place for travlers and history buffs', 'Beautifully demonstrate the rich history of Jaipur', 'A must-visit place in Jaipur'],
  },
  pageTitle: 'Unveiling the Splendors of City Palace Jaipur!',
  author: {
    authorName: 'Aadhar Bhatt',
    authorID: 'JI01',
  },
  storyId: 'cp_official',
  pageDescriptions: 'Immerse yourself in the rich history and vibrant culture of Jaipur as you explore the magnificent City Palace. Enjoy a blend of architectural marvels, royal tales, and cultural experiences.',
  pageSections: [
    {
      sectionID: 'sec_01',
      parentID: 'cp_official',
      sectionName: 'Blue room',
      sectionInfo: 'The Blue Room is a part of the Chandra Mahal, which is the residence of the royal family. The room is adorned with blue and white ceramics, and the walls are decorated with intricate floral patterns. The room is also home to a collection of miniature paintings, which depict scenes from Hindu mythology and the royal court.',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/zfcpklbiaew2xgiefzxf',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731697367/StoryMode/CityPalace/Audios/eblgy2qzhwfhoybphoxy.mp3',
      sectionDuration: '',
      Geolocation: '',
      moreLikeThis: ["sec_02", "sec_14"]
    },
    {
      sectionID: 'sec_02',
      parentID: 'cp_official',
      sectionName: 'Shobha niwas',
      sectionInfo: 'The Shobha Niwas is a grand hall that was used for royal receptions and ceremonies. The hall is decorated with intricate mirror work, and the walls are adorned with beautiful paintings. The hall is also home to a collection of royal artifacts, including weapons, armor, and jewelry.',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/femhefth9ieiqahdzwb3',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731697561/StoryMode/CityPalace/Audios/yjmwcew76zvz1kjrlo4o.mp3',
      sectionDuration: '',
      Geolocation: '',
      moreLikeThis: ["sec_01", "sec_14"]
    },
    {
      sectionID: 'sec_03',
      parentID: 'cp_official',
      sectionName: 'Lotus gate',
      sectionInfo: 'Lotus Gate is at south-west side of Pritam Niwas Chowk, adorned with intricate lotus petal designs it symbolizing summer. It is dedicated to Lord Shiva and Parvati, serving as an entry point for the Maharaja during the summer months. The gate showcases exquisite craftsmanship and vibrant colors, reflecting the artistic heritage of Rajasthan',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/teibk3l7madcwmc7704y',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731697732/StoryMode/CityPalace/Audios/dwt1t4dz8xrby8vrlaj0.mp3',
      sectionDuration: '',
      Geolocation: '',
      moreLikeThis: ["sec_03", "sec_07", "sec_10", "sec_13",  "sec_15"]
    },
    {
      sectionID: 'sec_04',
      parentID: 'cp_official',
      sectionName: 'Gangajalis',
      sectionInfo: 'The Gangajalis are a collection of silver vessels that were used to carry holy water from the Ganges River. They were created for Maharaja Sawai Madho Singh II in 1894. Weighing 345 kg each and capable of holding approximately 4,091 liters of water.',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/ttq2gcdlrxk7drivh3da',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731697782/StoryMode/CityPalace/Audios/rl7q04shjkhz1j8e6jtk.mp3',
      sectionDuration: '',
      Geolocation: '',
      moreLikeThis: ["sec_04", "sec_05", "sec_12"]
    },
    {
      sectionID: 'sec_05',
      parentID: 'cp_official',
      sectionName: 'Royal elephant of marble',
      sectionInfo: 'The royal elephant of marble at the entrance of the City Palace is a symbol of strength, wisdom, and prosperity. The statue is made of white marble and is a tribute to the royal elephants that were once used by the Maharajas of Jaipur.',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/e2rqxbqhg5acl3dttmxs',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731697823/StoryMode/CityPalace/Audios/r6i0om4cx617geiy1d9s.mp3',
      sectionDuration: '',
      Geolocation: '',
      moreLikeThis: ["sec_04", "sec_05", "sec_12"]
    },
    {
      sectionID: 'sec_06',
      parentID: 'cp_official',
      sectionName: 'decorative peacock mosaics',
      sectionInfo: 'The Peacock Gate is one of the most famous entrances to the City Palace. It is adorned with intricate peacock mosaics, which symbolize grace, beauty, and elegance. The gate is a stunning example of Rajput architecture and craftsmanship.',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/ashfms8oyzpmfwutoybj',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731697862/StoryMode/CityPalace/Audios/jlpjwuv6rkquxsl5wwzz.mp3',
      sectionDuration: '',
      Geolocation: '',
      moreLikeThis: ["sec_06", "sec_07", "sec_08"]
    },
    {
      sectionID: 'sec_07',
      parentID: 'cp_official',
      sectionName: 'Peacock gate',
      sectionInfo: 'The Peacock Gate is one of the most famous entrances to the City Palace. It is adorned with intricate peacock mosaics, which symbolize grace, beauty, and elegance. The gate is dedicated to Lord Vishnu and represents autumn.',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/ahpy4cc52tdcyaxgjqjb',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731697910/StoryMode/CityPalace/Audios/bwjbxszefucnda2qfya1.mp3',
      sectionDuration: '',
      Geolocation: '',
      moreLikeThis: ["sec_03", "sec_07", "sec_10", "sec_13",  "sec_15"]
    },
    {
      sectionID: 'sec_08',
      parentID: 'cp_official',
      sectionName: 'Jharokhas',
      sectionInfo: 'The Jharokhas are a series of ornate windows that overlook the courtyard of the City Palace. They were primarily used by the women of royal family to watch processions and ceremonies in the courtyard below.',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/padsinppqmwuheqgqibw',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731697996/StoryMode/CityPalace/Audios/ksqxyspfbxqddeafxiyu.mp3',
      sectionDuration: '',
      Geolocation: '',
    },
    {
      sectionID: 'sec_09',
      parentID: 'cp_official',
      sectionName: 'Mubarak mahal',
      sectionInfo: 'Mubarak Mahal, meaning "Auspicious Palace," is a prominent structure within the City Palace complex in Jaipur, built in the late 19th century by Maharaja Madho Singh II. This architectural marvel showcases a blend of Islamic, Rajput, and European styles, featuring intricate stone carvings and expansive balconies and is home to a collection of royal costumes, textiles, and jewelry.',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/rc1fi2funlygtkdzii0y',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731698075/StoryMode/CityPalace/Audios/jt79hlpdywd1dplyaa8t.mp3',
      sectionDuration: '',
      Geolocation: '',
      moreLikeThis: ["sec_09", "sec_11"]
    },
    {
      sectionID: 'sec_10',
      parentID: 'cp_official',
      sectionName: 'Green gate',
      sectionInfo: 'The Green Gate, also known as the Leheriya Gate, is one of four ornate entrances in the Pritam Niwas Chowk of Jaipur\'s City Palace. It symbolizes Spring and is dedicated to Lord Ganesha',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/byddrlpjqjdscrvzswtq',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731698108/StoryMode/CityPalace/Audios/vgvqogcywjq0xj95jquh.mp3',
      sectionDuration: '',
      Geolocation: '',
      moreLikeThis: ["sec_03", "sec_07", "sec_10", "sec_13",  "sec_15"]
    },
    {
      sectionID: 'sec_11',
      parentID: 'cp_official',
      sectionName: 'Chandra mahal',
      sectionInfo: 'Chandra Mahal, built by Maharaja Sawai Jai Singh II between 1727 and 1732, is a significant part of Jaipur\'s City Palace. This seven-story structure symbolizes the city\'s royal heritage and serves as the current residence of the Maharaja, showcasing a blend of Rajput, Mughal, and European architectural styles.',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/vd2k1gw1nc3wgy1fcocw',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731698144/StoryMode/CityPalace/Audios/stqdv3oavc141rv2ajjm.mp3',
      sectionDuration: '',
      Geolocation: '',
      moreLikeThis: ["sec_09", "sec_11"]
    },
    {
      sectionID: 'sec_12',
      parentID: 'cp_official',
      sectionName: 'Ornate golden doors',
      sectionInfo: 'The ornate golden doors of the City Palace are a stunning example of Rajput architecture and craftsmanship. Adorned with intricate carvings and designs of gold and silver leaf. They are a symbol of the opulence and grandeur of the royal family.',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/t0h737iieel99xqoszyk',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731698184/StoryMode/CityPalace/Audios/yr9ticc9ak619lkecypq.mp3',
      sectionDuration: '',
      Geolocation: '',
      moreLikeThis: ["sec_04", "sec_05", "sec_12"]
    },
    {
      sectionID: 'sec_13',
      parentID: 'cp_official',
      sectionName: 'Rose gate',
      sectionInfo: ' The Rose Gate symbolizes winter and is dedicated to the goddess Devi, showcasing exquisite craftsmanship and vibrant decorations that reflect the artistic heritage of Rajasthan',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/o7qhwz8e0psgkkovs43w',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731698215/StoryMode/CityPalace/Audios/oxyssfdoj0nmgrmb7zhh.mp3',
      sectionDuration: '',
      Geolocation: '',
      moreLikeThis: ["sec_03", "sec_07", "sec_10", "sec_13",  "sec_15"]
    },
    {
      sectionID: 'sec_14',
      parentID: 'cp_official',
      sectionName: 'Rang mandir',
      sectionInfo: 'The Rang Mandir also known as the Hall of Mirrors is a private chamber within the City Palace that was used by the royal family for entertainment, relaxation or ceremonial purposes. The chamber is adorned with intricate mirror work and colorful glass mosaics, creating a vibrant and festive atmosphere.',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/nttzbeacx0gblom5jrln',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731698251/StoryMode/CityPalace/Audios/vxyezsa3sqpgwk9rfqgk.mp3',
      sectionDuration: '',
      Geolocation: '',
      moreLikeThis: ["sec_01", "sec_02"]
    },
    {
      sectionID: 'sec_15',
      parentID: 'cp_official',
      sectionName: 'Pritam Niwas Chowk',
      sectionInfo: 'Pritam Niwas Chowk is a courtyard within the City Palace that houses four ornate gates, each dedicated to a different Hindu god and symbolizing a different season. The courtyard is a stunning example of Rajput architecture and craftsmanship, featuring intricate carvings, vibrant colors, and beautiful decorations.',
      sectionImgID: '',
      sectionImg: 'https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/yhkzkum0avj9c7fzqwjx',
      sectionAudio: 'https://res.cloudinary.com/dmh7qgsbj/video/upload/v1731698311/StoryMode/CityPalace/Audios/qjowm2mooemsygpodvos.mp3',
      sectionDuration: '',
      Geolocation: '',
      moreLikeThis: ["sec_03", "sec_07", "sec_10", "sec_13",  "sec_15"]
    },
  ],
  languages: {
    primaryLanguage: 'English',
    allLanguages: ['English ', 'Hindi ', 'Marathi ', 'Kannada ', 'Gujarati ', 'Punjabi '],
  },
  totalDuration: '1 Hour, 32 mins',
  electiveSectionDetails: {
    isTicketIncluded: true,
    isWheelChairAccessible: true,
  },
  moreDetails: [
    {
      highlights: 'Highlights',
      description: ['Enjoy a guided tour of Stamford Bridge, home of Chelsea Football Club', 'Get a behind-the-scenes look at one of the world '],
    },
    {
      highlights: 'Full description',
      description: ['Enjoy a guided tour of Stamford Bridge, home of Chelsea Football Club', 'Get a behind-the-scenes look at one of the world '],
    },
    {
      highlights: 'Inclusions',
      description: ['Downloadable app', 'Guide', 'Guided tour of the museum'],
    },
    {
      highlights: 'Start point',
      description: ['Enjoy a guided tour of Stamford Bridge, home of Chelsea Football Club', 'Get a behind-the-scenes look at one of the world '],
    },
    {
      highlights: 'What to bring',
      description: ['Enjoy a guided tour of Stamford Bridge, home of Chelsea Football Club', 'Get a behind-the-scenes look at one of the world '],
    },
    {
      highlights: 'Know before you go',
      description: ['Enjoy a guided tour of Stamford Bridge, home of Chelsea Football Club', 'Get a behind-the-scenes look at one of the world '],
    },
  ],
  pricingDetails: {
    orignalPrice: 1234,
    finalPrice: 2334,
    discounts: {
      discountID: '',
      discountType: '',
      discountTitle: '',
      discountValue: '',
    },
  },

};
