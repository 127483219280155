import React from 'react';
import PropTypes from 'prop-types';

function HeaderBarWrapper({ children }) {
  return (
    <div className="flex items-center justify-evenly gap-2 py-4">
      {children}
    </div>
  );
}
HeaderBarWrapper.propTypes = {
  children: PropTypes.element,
};
HeaderBarWrapper.defaultProps = {
  children: null,
};

export { HeaderBarWrapper };
