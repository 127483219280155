import React from 'react';
import PropTypes from 'prop-types';
import {
  IconFactory,
  IconFactoryColors,
  IconFactorySizes,
  IconFactoryTypes,
} from 'commons/Icons/IconFactory';
import CardBoundary from 'commons/Cards/elements/CardBoundary';
import { APP_ROUTES } from 'configs/AppRoutes';

const DestinationList = ({ DestinationsList }) => (
    <div>
        <h2 className="text-lg font-bold uppercase tracking-wider">All Guides</h2>
        <div className="my-5">
            {DestinationsList.map(
        ({ spotImg, listingName, description, location }, index) => (
            <CardBoundary navigateTo={APP_ROUTES.APP.SUB_PAGES.CATALOGPAGES.DETAILS_PAGE}>
                <div
                  key={index}
                  className="my-2 h-32 flex items-start p-2 justify-between gap-4 rounded-2xl border"
                >
                    <img
                      src={spotImg}
                      className="w-32 h-full rounded-xl"
                      alt="destionation View"
                    />
                    <div className="flex flex-col flex-1 items-start justify-around">
                        <div className="flex items-center gap-1">
                            <IconFactory
                              iconName={IconFactoryTypes.LANDMARK}
                              iconColor={IconFactoryColors.RED}
                              iconSize={IconFactorySizes.smaller}
                            />
                            <small>{location}</small>
                        </div>
                        <h2 className="font-bold uppercase">{listingName}</h2>
                        <p className="overflow-hidden text-sm line-clamp-3">{description}</p>
                    </div>
                </div>
            </CardBoundary>
        ),
      )}
        </div>
    </div>
);

DestinationList.propTypes = {
  DestinationsList: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export { DestinationList };
