import React from 'react';
import PropTypes from 'prop-types';

const ButtonStyleType = {
  edit: '  w-24 h-7 text-sm font-bold outline-none border-none rounded-lg bg-white text-black',
  signOut: 'text-md mx-auto my-2 flex rounded-xl border-none bg-red-700 px-6 py-2 font-semibold outline-none',
};

function ButtonElement({ children, btnStyle }) {
  return (
    <button className={`${ButtonStyleType[btnStyle]}`} type="button">
      {children}
    </button>
  );
}
ButtonElement.propTypes = {
  btnStyle: PropTypes.oneOf(['edit', 'signOut']).isRequired,
  children: PropTypes.string.isRequired,
};

export default ButtonElement;
