import { IconFactory, IconFactoryTypes } from 'commons/Icons/IconFactory';
import React from 'react';

function NotificatinBar() {
  return (
    <div className="flex items-center rounded-full bg-gray-800 p-2 text-white">
      <IconFactory iconName={IconFactoryTypes.NOTIFICATION} />
    </div>
  );
}

export default NotificatinBar;
