import React, { Suspense, lazy, useEffect, useState } from 'react';
import detailedView from './detailedCards.data';
// import HorizontalCardContainer from 'commons/CardsContainer/HorizontalCardContainer';
// import { LargeCardSkeleton } from 'commons/skeletons/compositions/LargeCardSkeleton';

const DetailedViewCard = lazy(() => import('./detailedViewCardElement'));

function TextCards() {
  const np = new Promise((resolve) => {
    setTimeout(() => {
      resolve(detailedView);
    }, 5000); // Simulate a 2-second delay
  });

  const [data, setData] = useState(null); // Initialize state to hold the data

  useEffect(() => {
    np.then((fetchedData) => {
      setData(fetchedData); // Update the state with the fetched data
    });
  }, []); // Empty dependency array means this effect runs once on mount

  if (data == null) {
    return <div className="text-white">Data is null</div>;
  }
  return (
  // <HorizontalCardContainer>
    <>
      {data.map((card, index) => (
        <DetailedViewCard key={index} card={card} />
      ))}
    </>
  // </HorizontalCardContainer>
  );
}

function DetailedViewCards() {
  return (
    <>
      <h1 className="p-4 text-3xl font-bold">Detailed View Cards</h1>
      <Suspense fallback={<div className="text-white">Loading...</div>}>
        <TextCards />
      </Suspense>
    </>
  );
}

export default DetailedViewCards;
