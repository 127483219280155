import React from 'react';
import { RatingSection } from 'commons/RatingAndReview/RatingSection';
import PropTypes from 'prop-types';
import { CommentSection } from 'commons/RatingAndReview/CommentSection';
import { Catagorys } from './catagorys';

function HighlightAndReviews({ rating, reviews, pageCatagory }) {
  return (
    <div className="flex justify-between pt-3 items-center">
      <Catagorys pageCatagory={pageCatagory} />
      <div className="flex justify-between gap-4">
        <RatingSection stars="false" rating={rating} />
        <CommentSection reviews={reviews} />
      </div>
    </div>
  );
}

HighlightAndReviews.propTypes = {
  rating: PropTypes.number.isRequired,
  reviews: PropTypes.number.isRequired,
  pageCatagory: PropTypes.string.isRequired,
};

export { HighlightAndReviews };
