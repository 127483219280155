import {
  IconFactory,
  IconFactoryColors,
  IconFactorySizes,
  IconFactoryTypes,
} from 'commons/Icons/IconFactory';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const AccordionElement = ({
  content,
  title,
  sectionId,
  AddNewSection,
  setAddNewSection,
}) => {
  const [active, setInactive] = useState(false);

  function handleDelete() {
    const idToDelete = sectionId;
    const updatedSections = Object.fromEntries(
      Object.entries(AddNewSection).filter(([id]) => id !== idToDelete),
    );
    setAddNewSection(updatedSections);
  }

  return (
    <div className="my-5 rounded-xl border hover:border-purple-400">
      <div className="flex items-center justify-between rounded-xl px-4 transition-all">
        <div
          onClick={() => setInactive(!active)}
          aria-label="accordion"
          role="button"
          tabIndex={0}
          onKeyDown={(event) => event.key}
          className="transition-ll flex items-center justify-evenly gap-2 px-4 py-3 text-lg font-semibold hover:text-purple-400"
        >
          <span>
            {active ? (
              <IconFactory
                iconName={IconFactoryTypes.UP_ARROW}
                iconSize={IconFactorySizes.small}
              />
            ) : (
              <IconFactory
                iconName={IconFactoryTypes.DOWN_ARROW}
                iconSize={IconFactorySizes.small}
              />
            )}
          </span>
          <div>{title}</div>
        </div>
        <span
          onClick={() => handleDelete()}
          aria-label="delete"
          onKeyDown={(event) => event.key}
          tabIndex={0}
          role="button"
          className="rounded-lg p-1"
        >
          <IconFactory
            iconName={IconFactoryTypes.DELETE}
            iconSize={IconFactorySizes.small}
            iconColor={IconFactoryColors.RED}
          />
        </span>
      </div>

      {active && (
        <div className="mt-1 rounded-xl px-6 py-4 text-white ">{content}</div>
      )}
    </div>
  );
};

AccordionElement.propTypes = {
  content: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  AddNewSection: PropTypes.string.isRequired,
  setAddNewSection: PropTypes.func.isRequired,
};

export { AccordionElement };
