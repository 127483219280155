import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { RatingSection } from 'commons/RatingAndReview/RatingSection';
import HorizontalCardContainer from 'commons/CardsContainer/HorizontalCardContainer';
import { LargeCardSkeleton } from 'commons/skeletons/compositions/LargeCardSkeleton';
import CardBoundary from '../elements/CardBoundary';
import detailedView from './detailedCards.data';
import CardImage from '../elements/CardImage';
import {
  CardBody, CardHeadingChip, CardTitle, PriceSection, Wrapper,
} from '../elements/CardSections';

export function DetailedViewCard({ imgURL, title, chipText, rating, ratingCount, price, navigateTo }) {
  return (
    <CardBoundary cardType="large" navigateTo={navigateTo}>
      <CardImage imageSrc={imgURL} imageTitle={title} imageType="large" />
      <CardBody>
        <Wrapper>
          <CardHeadingChip chipText={chipText} />
          <CardTitle title={title} />
        </Wrapper>
        <Wrapper>
          <RatingSection rating={rating} ratingCount={ratingCount} />
          <PriceSection price={price} />
        </Wrapper>
      </CardBody>
    </CardBoundary>
  );
}
DetailedViewCard.propTypes = {
  imgURL: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  chipText: PropTypes.string,
  rating: PropTypes.number,
  ratingCount: PropTypes.number,
  price: PropTypes.number,
  navigateTo: PropTypes.string,
};
DetailedViewCard.defaultProps = {
  chipText: '',
  rating: 0,
  ratingCount: 0,
  price: null,
  navigateTo: null,
};

function DetailedViewCards() {
  const renderCards = () => detailedView.map((card, index) => <DetailedViewCard key={index} card={card} />);
  return (
    <div className="pb-40">
      <h1 className="text-3xl font-bold p-4">Detailed View Cards</h1>
      <HorizontalCardContainer>
        <Suspense fallback={<LargeCardSkeleton />}>
          {renderCards()}
        </Suspense>
      </HorizontalCardContainer>
    </div>
  );
}

export default DetailedViewCards;
