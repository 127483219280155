import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MutlipleOptionInput } from './elements/mutlipleOptionsElement';
import { formElementStyle } from '../elements/formElementsStyle';

const { labelStyle, dropDownInput, constantStyle } = formElementStyle;

function DropdownInput({
  label,
  name,
  dropDownOptions,
  multipleOptions,
  formHandler,
  required,
}) {
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);
  const id = name;

  const handleChange = useCallback(
    (event) => {
      event.preventDefault();
      setInputValue(event.target.value);
      if (selectedValues.includes(event.target.value)) {
      // Option already selected, so no change needed
      } else {
        setSelectedValues([...selectedValues, event.target.value]);
      }
    },
    [selectedValues],
  );

  const handleBlur = useCallback(() => {
    if (inputValue.trim() === '') {
      setInputError('Please select an type');
    } else {
      setInputError('');
    }
  }, [inputValue]);

  //   useEffect(() => {
  //     formHandler({ id, error: inputError });
  //   }, [inputError]);

  return (
    <div>
      <label htmlFor={id} className={labelStyle}>
        <div>
          {label} {required && <span className={constantStyle}>*</span>}
        </div>
        {multipleOptions && <MutlipleOptionInput selectedValues={selectedValues} setSelectedValues={setSelectedValues} />}
        <select
          id={id}
          name={name}
          onChange={handleChange}
          value={inputValue}
          onBlur={handleBlur}
          className={dropDownInput}
          required
        >
          {dropDownOptions.map((options, index) => (
            <option key={index} value={options}>
              {options}
            </option>
          ))}
        </select>
        {inputError && <p className={constantStyle}>{inputError}</p>}
      </label>
    </div>
  );
}

DropdownInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formHandler: PropTypes.func.isRequired,
  dropDownOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  multipleOptions: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
};

export { DropdownInput };
