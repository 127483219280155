import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconFactory, IconFactoryTypes } from 'commons/Icons/IconFactory';

function BackButton() {
  const navigate = useNavigate();

  // Function to handle the back button click
  const handleBackClick = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  // Function to handle keyboard events
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleBackClick(event);
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleBackClick}
      onKeyDown={handleKeyDown}
      aria-label="Go back"
    >
      <IconFactory iconName={IconFactoryTypes.BACK_BUTTON} />
    </div>
  );
}

export default BackButton;
