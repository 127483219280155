import React from 'react';
// import { CarouselSection } from 'commons/carousel/carouselSectionDummy';
// import Storys from 'commons/storys/storysDummy';
// import CardsText from 'commons/Cards/cardsContent/cardsTextDummy';
// import TitleCards from 'commons/Cards/smallCards/titleCardsDummy';
// import GlimpsesCards from 'commons/Cards/mediumCards/glimpsesCardsDummy';
// import { LargeCardSkeleton } from '../../../commons/skeletons/compositions/LargeCardSkeleton';
// import { AllCombiend } from '../../../commons/skeletons/compositions/AllCombined';
import DetailedViewCards from 'commons/Cards/largeCards/DetailedViewCardDummy';

// deprecated
function AppLanding() {
  return (
    <>
      {/* <CarouselSection />
      <Storys />
      <CardsText /> */}
      {/* <TitleCards />
      <GlimpsesCards /> */}
      <DetailedViewCards />
      {/* <LargeCardSkeleton /> */}
      {/* <AllCombiend /> */}
    </>
  );
}

export { AppLanding };
