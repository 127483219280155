import React from 'react';
import {
  Header, Footer, PrimarySection, WhyUsSection, Slogan1, GettingStarted, Querys,
} from './staticSections';

function LandingPage() {
  return (
    <>
      <Header />
      <PrimarySection />
      <WhyUsSection />
      <Slogan1 />
      <Querys />
      <GettingStarted />
      <Footer />
    </>
  );
}

export default LandingPage;
