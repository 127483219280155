import { APP_ROUTES } from 'configs/AppRoutes';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function TicketBanner() {
    const navigate = useNavigate();
  return (
      <div className="sticky bottom-0 left-0 right-0 mt-4 flex items-center justify-between border-y-[0.4px] border-gray-500 bg-black py-2">
          <div className="flex flex-col items-center justify-around font-semibold">
              <span>From</span>
              <p>$ 10 </p>
          </div>
          <div className="">
              <button
                className="w-36 rounded-full bg-blue-500 py-2 text-lg text-white"
                type="button"
                onClick={() => navigate(APP_ROUTES.APP.PLAYER_PATH)}
              >
                  Start
              </button>
          </div>
      </div>
  );
}

export { TicketBanner };
