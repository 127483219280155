/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { BaseErrorBoundary } from './baseErrorBoundary';

function Fallback() {
  return (
    <div className="flex flex-col items-center">
      <img src="/originalImgs/somethingWenrWrong.png" alt="something went wrong" className="mx-auto" />

      <p className="text-red-500 m-4">Something went wrong</p>
      <button
        onClick={() => window.location.reload()}
        type="button"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Reload Page
      </button>
    </div>
  );
}

function AppLevelErrorBoundary({ children }) {
  // Simulate an error for demonstration purposes
  return (
    <BaseErrorBoundary
      Fallback={<Fallback />}
    >
      {children}
    </BaseErrorBoundary>
  );
}

AppLevelErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

export { AppLevelErrorBoundary };
