import React from 'react';
import PropTypes from 'prop-types';

const cardImgObject = {
  small: ' w-36 h-48 lg:w-32 lg:h-44 max-w-none object-cover shadow-3inn rounded-lg',
  medium: ' w-40 h-40 max-w-none object-cover rounded-lg ',
  large: 'object-cover rounded-t-lg w-full h-56',
  storyImg: 'w-16 h-16 rounded-full border-4 border-solid border-blue-500 max-w-none',
  fullsize: ' ',
};

function CardImage({ imageType, imageSrc, imageTitle }) {
  return (
    <img
      className={`${cardImgObject[imageType]}`}
      src={imageSrc}
      alt={imageTitle}
    />
  );
}
CardImage.propTypes = {
  imageType: PropTypes.oneOf(['small', 'medium', 'large', 'storyImg']).isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageTitle: PropTypes.string.isRequired,
};

export default CardImage;
