import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

const AllCategories = ({ handleClick }) => {
  const [selected, setSelected] = useState('All Categories');

  function handleKeyDown(e) {
    e.preventDefault();
    if (e.key === 'Enter') {
      handleClick(e);
    }
  }
  return (
    <div
      className="flex flex-col items-center justify-center"
      onClick={(e) => handleClick(selected, e)}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => handleKeyDown(e)}
    >
      <div
        className="flex h-20 w-20 items-center justify-center rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500
    font-serif text-4xl font-bold tracking-wider focus:outline-none focus:ring focus:ring-violet-300"
      >
        All
      </div>
      <div>All Destinations</div>
    </div>
  );
};

AllCategories.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export { AllCategories };
