import React from 'react';
import PropTypes from 'prop-types';

const TypeFileElement = ({ selectedFiles, setSelectedFiles }) => {
  function handleDelete(fileToDelete) {
    let selectedFilesArray = [...selectedFiles];
    selectedFilesArray = selectedFilesArray.filter(
      (files) => files.name !== fileToDelete,
    );
    setSelectedFiles(selectedFilesArray);
  }

  return (
    <div className="no-scrollbar mb-1 flex flex-1 items-center h-16 border-purple-500 justify-start gap-2 overflow-y-hidden overflow-x-scroll rounded-2xl border px-4 py-2">
      {selectedFiles.map((file, index) => (
        <div
          key={index}
          className="relative min-w-14"
        >
          <img
            src={URL.createObjectURL(file)}
            alt={file.name}
            className="size-14 rounded-full"
          />
          {/* <small>{file.name}</small> */}
          <button
            type="button"
            key={index}
            onClick={() => handleDelete(file.name)}
            className=" absolute right-0 top-0 w-5 rounded-xl text-xs font-extrabold text-red-400 outline-none backdrop-blur-md"
          >
            X
          </button>
        </div>
      ))}
    </div>
  );
};

TypeFileElement.propTypes = {
  selectedFiles: PropTypes.string.isRequired,
  setSelectedFiles: PropTypes.string.isRequired,
};

export { TypeFileElement };
