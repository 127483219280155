/* eslint-disable default-param-last */
import { PLAYER_ACTIONS as ACTION } from './player.actions';

const initialState = {
    defaultPlayerImage: "https://res.cloudinary.com/dmh7qgsbj/image/upload/f_auto,q_auto/v1/StoryMode/CityPalace/Images/nggluzo1bigzrlugkrfr",
    defaultPlayerAudio: null,
    viewedMedia: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION.RESET_PLAYER_STATE:
        return { ...initialState };
    case ACTION.SET_PLAYER_MEDIA:
      return {
        ...state,
        ...action.payload,
        viewedMedia: [...state.viewedMedia, action.payload.playerMediaId],
      };
    case ACTION.SET_MORE_LIKE_THIS_PLAYLIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
