import React from 'react';
import PropTypes from 'prop-types';
import { TicketInclude, WheelChair } from './activities';

function ElectiveSectionsDetails({ details }) {
  const { isTicketIncluded, isWheelChairAccessible } = details;
  return (
    <>
      {isTicketIncluded && <TicketInclude />}
      {isWheelChairAccessible && <WheelChair />}
    </>
  );
}
ElectiveSectionsDetails.propTypes = {
  details: PropTypes.shape({
    isTicketIncluded: PropTypes.bool.isRequired,
    isWheelChairAccessible: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ElectiveSectionsDetails;
