import React from 'react';
import {
  BrowserRouter,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Portal from './portal/portal';
import theme from './commons/theme';
import { AppLevelErrorBoundary } from './commons/ErrorBoundary';
// import './App.css';

function App() {
  return (
      <ThemeProvider theme={theme}>
          <BrowserRouter>
              <AppLevelErrorBoundary>
                  <Portal />
              </AppLevelErrorBoundary>
          </BrowserRouter>
      </ThemeProvider>
  );
}
export default App;
