export const ACTION = {
  PROFILE_DETAILS: 'PROFILE_DETAILS',
  PROFILE_STATUS: 'PROFILE_STATUS',
};

export function sayHiAction() {
  return (dispatch) =>
      dispatch({
          type: ACTION.PROFILE_DETAILS,
          payload: { userName: 'Umang' },
      })
};
