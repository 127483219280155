import React from 'react';
import './header.css';
import { APP_ROUTES } from 'configs/AppRoutes';

const headerImg = '/originalImgs/removedBgImgs/header_img.png';

function Header() {
  return (
    <header>
      <div className="container">
        <div className="heroDiv">
          <a href="/" className="brand">
            <img src={headerImg} alt="StoryMode logo" />
          </a>
          <h2>StoryMode</h2>
        </div>
        <div className="list">
          <ul>
            <li><a href={APP_ROUTES.LANDING_PAGE.JOIN_WAITLIST}>Sign up</a></li>
            <li><a href={APP_ROUTES.LANDING_PAGE.JOIN_WAITLIST}>Log in</a></li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
