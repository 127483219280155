import HorizontalCardContainer from "commons/CardsContainer/HorizontalCardContainer";
import { detailsPageJSON } from "portal/app/subPages/catalogPages/detailsPage/detailsPage.data";
import React from "react";
import { connect } from "react-redux";
import { SetMoreLikeThisPlaylist, SetPlayerMediaAction } from "../player.actions";

function MoreLikeThisComponent(props) {
    const { playlist, moreLikeThisIds, nowPlayingId, viewedMedia } = props;
    const { SetPlayersMedia } = props;
    
    // from playlist, get the details of the audios that are in moreLikeThisIds
    const moreLikeThisData = playlist.filter((audioDetails) =>
        audioDetails.sectionID !== nowPlayingId && !viewedMedia.includes(audioDetails.sectionID) && moreLikeThisIds.includes(audioDetails.sectionID)
    );

    // return null if moreLikeThisIds is empty array
    if (!moreLikeThisData.length) {
        return null;
    }

    return (
        <div className="my-2">
            <div className="mt-4 mb-3 text-xl font-bold tracking-wider">
                <h1>More Like This</h1>
            </div>

            <HorizontalCardContainer>
                {moreLikeThisData.map((audioDetails, key) => (
                    <div
                        key={key}
                        className="flex min-w-24 flex-col items-center justify-center text-center mx-1"
                        onClick={(e) => SetPlayersMedia(audioDetails)}
                        role="button"
                        tabIndex={0}
                        onKeyDown={(e) => SetPlayersMedia(audioDetails)}
                    >
                        <img
                            className="size-20 rounded-full bg-cover"
                            src={audioDetails.sectionImg}
                            alt="category"
                            />
                        <p className="break-words overflow-hidden whitespace-normal tracking-tight">{audioDetails.sectionName}</p>
                    </div>
                ))}
            </HorizontalCardContainer>
        </div>
    );
}

export const mapStateToProps = (state) => {
    return {
      playlist: state.player.playlist || detailsPageJSON.pageSections,
      moreLikeThisIds: state.player.moreLikeThisPlaylist || [],
      nowPlayingId: state.player.playerMediaId || null,
      viewedMedia: state.player.viewedMedia || [],
    };
  };
  
  export const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      dispatch,
      SetPlayersMedia: (audioDetails) => {
        dispatch(SetPlayerMediaAction(audioDetails));
        dispatch(SetMoreLikeThisPlaylist(audioDetails));
      },
    };
  };
  
  // Connect the component and export it
  const MoreLikeThisSection = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MoreLikeThisComponent);
  
  export { MoreLikeThisSection };