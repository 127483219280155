const detailedView = [
  {
    link: '//',
    img: '/originalImgs/c3.jpg',
    chipText: 'Guided Tour',
    title: 'London: Chelsea Football Club Stadium and Museum Tour',
    price: '2964',
    rating: '4.7',
    reviewCount: '1234',
  },
  {
    link: '//',
    img: '/originalImgs/c3.jpg',
    chipText: 'Guided Tour',
    title: 'London: Chelsea Football Club Stadium and Museum Tour',
    price: '2964',
    rating: '4.7',
    reviewCount: '1234',
  },
  {
    link: '//',
    img: '/originalImgs/c3.jpg',
    chipText: 'Guided Tour',
    title: 'London: Chelsea Football Club Stadium and Museum Tour',
    price: '2964',
    rating: '4.7',
    reviewCount: '1234',
  },
  {
    link: '//',
    img: '/originalImgs/c3.jpg',
    chipText: 'Guided Tour',
    title: 'London: Chelsea Football Club Stadium and Museum Tour',
    price: '2964',
    rating: '4.7',
    reviewCount: '1234',
  },
];

export default detailedView;
