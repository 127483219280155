// config.dev.js

// const BASE_URL = 'http://localhost:3009';
const domain = window.location.href.split('/')[2].split(':')[0];
const backendPort = '3009';
const BASE_URL = `http://${domain}:${backendPort}`;

const devConfig = {
  LANDING_POPULARCITIES: `${BASE_URL}/mainPage/popularLocation`,
  LANDING_CURATEDSTORIES: `${BASE_URL}/details/stories`,
};

export default devConfig;
