import React from 'react';
import PropTypes from 'prop-types';

function HorizontalCardContainer({ children }) {
  return (
    <div className="flex gap-2 py-4 justify-start items-start overflow-x-scroll whitespace-nowrap no-scrollbar">
      {children}
    </div>
  );
}
HorizontalCardContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HorizontalCardContainer;
