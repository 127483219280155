import { APP_ROUTES } from 'configs/AppRoutes';
import DetailsPage from './detailsPage/detailsPage';
import { MoreDetailsPage } from './detailsPage/elements/moreDetailsPage/moreDetailsPage';

const catalogPageRoutes = [
  {
    path: APP_ROUTES.APP.SUB_PAGES.CATALOGPAGES.DETAILS_PAGE,
    View: DetailsPage,
  },
  {
    path: APP_ROUTES.APP.SUB_PAGES.CATALOGPAGES.MORE_DETAILS_PAGE,
    View: MoreDetailsPage,
  },
];

export {
  catalogPageRoutes,
};
