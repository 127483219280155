/* eslint-disable max-len */
import React from 'react';
import { useNavigate } from 'react-router';

function RouteNotFound() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center">
      <img src="/originalImgs/routeNotFound.gif" alt="something went wrong" className="mx-auto" />

      <p className="text-red-500 m-4">Route not found</p>
      <button
        onClick={() => navigate(-1)}
        type="button"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Go Back
      </button>
    </div>
  );
}

export { RouteNotFound };
