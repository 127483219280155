import React from 'react';
import { SearchBar, NotificationBar, BackButton } from '../headerComponents';
import { HeaderBarWrapper } from '../elements/headerBarWrapper';

function HeaderBar() {
  return (
    <HeaderBarWrapper>
      <BackButton />
      <SearchBar />
      <NotificationBar />
    </HeaderBarWrapper>
  );
}

export { HeaderBar };
