import React from 'react';
import './whyUs.css';

const whyUsDataArr = [
  {
    img: '/images/s1.png',
    heading: 'Dive into Local Stories.',
    text: 'Unlock a world of stories at landmarks, shared by locals and historians.',
  },
  {
    img: '/images/s2.png',
    heading: 'Listen and Learn.',
    text: 'Indulge in captivating, informative, and entertaining content while at the location it happened.',
  },
  {
    img: '/images/s3.png',
    heading: 'Make it yours.',
    text: 'Connect with your community, embrace local narratives, and build a deeper city bond.',
  },
  {
    img: '/images/s4.png',
    heading: 'Unearth the Local Magic.',
    text: 'Uncover local magic, from landmarks to hidden gems, and see your city anew.',
  },
];

function WhyUsSection() {
  return (
    <div className="prosContainer">
      <h1>Why StoryMode?</h1>
      <section>
        <div className="proList">
          {whyUsDataArr.map((cardData, i) => (
            <div key={i} className="proCard">
              <img src={cardData.img} alt="" />
              <div className="cardText">
                <h3>{cardData.heading}</h3>
                <p>{cardData.text}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default WhyUsSection;
