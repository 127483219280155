export const DestinationPageJSON = {
  destinationList: [
    {
      spotImg: '/images/HawaMahal.png',
      category: 'Monuments',
      listingName: 'Hawa Mahal',
      location: 'Jaipur, Rajasthan',
      description:
        'Jaipurs iconic palace, offers panoramic views of the city, known for its honeycomb-like structure and historical significance.',
    },
    {
      spotImg: '/images/amberFort.jpeg',
      category: 'Heritage',
      listingName: 'Amber Fort',
      location: 'Jaipur, Rajasthan',
      description:
        'Jaipurs iconic palace, offers panoramic views of the city, known for its honeycomb-like structure and historical significance.',
    },
    {
      spotImg: '/images/OIP.jpeg',
      category: 'Heritage',
      listingName: 'JalMahal',
      location: 'Jaipur, Rajasthan',
      description:
        'Jaipurs iconic palace, offers panoramic views of the city, known for its honeycomb-like structure and historical significance.',
    },
    {
      spotImg: '/images/AlbertHall.avif',
      category: 'Museum',
      listingName: 'Albert Hall',
      location: 'Jaipur, Rajasthan',
      description:
        'Jaipurs iconic palace, offers panoramic views of the city, known for its honeycomb-like structure and historical significance.',
    },
    {
      spotImg: '/images/Jaigarh-Fort.jpg',
      category: 'Heritage',
      listingName: 'Jaigarh Fort',
      location: 'Jaipur, Rajasthan',
      description:
        'Jaipurs iconic palace, offers panoramic views of the city, known for its honeycomb-like structure and historical significance.',
    },
    {
      spotImg: '/images/PatrikaGate.jpeg',
      category: 'Monuments',
      listingName: 'Patrika Gate',
      location: 'Jaipur, Rajasthan',
      description:
        'Jaipurs iconic palace, offers panoramic views of the city, known for its honeycomb-like structure and historical significance.',
    },
    {
      spotImg: '/images/fun-kingdom.jpg',
      category: 'Adventure',
      listingName: 'Fun Kingdom',
      location: 'Jaipur, Rajasthan',
      description:
        'Jaipurs iconic palace, offers panoramic views of the city, known for its honeycomb-like structure and historical significance.',
    },
    {
      spotImg: '/images/kishanBagh.jpg',
      category: 'Adventure',
      listingName: 'Kishan Bagh',
      location: 'Jaipur, Rajasthan',
      description:
        'Jaipurs iconic palace, offers panoramic views of the city, known for its honeycomb-like structure and historical significance.',
    },
  ],
  Categories: [
    {
      categorieImg: '/images/Adventure.jpeg',
      categorieType: 'Adventure',
    },
    {
      categorieImg: '/images/worldHeritage.jpg',
      categorieType: 'Heritage',
    },
    {
      categorieImg: '/images/acient.webp',
      categorieType: 'Museum',
    },
    {
      categorieImg: '/images/haritage.jpeg',
      categorieType: 'Monuments',
    },
    {
      categorieImg: '/images/Adventure.jpeg',
      categorieType: 'Adventure',
    },
    {
      categorieImg: '/images/worldHeritage.jpg',
      categorieType: 'Heritage',
    },
    {
      categorieImg: '/images/acient.webp',
      categorieType: 'Museum',
    },
  ],
};
