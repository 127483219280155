import React from 'react';
import PropTypes from 'prop-types';
import CardBoundary from '../elements/CardBoundary';
import CardImage from '../elements/CardImage';

export function TitleCard({ imgURL, cardTitle, navigateTo }) {
  return (
    <CardBoundary cardType="small" navigateTo={navigateTo}>
      <CardImage
        imageType="small"
        imageSrc={imgURL}
        imageTitle={cardTitle}
      />
      <div className="text-white">
        <div className="absolute bottom-4 left-4 text-xl font-extrabold">
          {cardTitle}
        </div>
      </div>
    </CardBoundary>
  );
}

TitleCard.propTypes = {
  imgURL: PropTypes.string.isRequired,
  cardTitle: PropTypes.string.isRequired,
  navigateTo: PropTypes.string,
};

TitleCard.defaultProps = {
  navigateTo: null,
};
