import React from 'react';
import PropTypes from 'prop-types';
import { IconFactory, IconFactorySizes, IconFactoryTypes } from 'commons/Icons/IconFactory';

function MoreDetailsPage({ moreDetails }) {
  return (
    <>
      <h1 className="mt-5 pb-2 text-xl font-extrabold">More Details</h1>
      {moreDetails.map((details) => (
        <div>
          <ul className="flex items-center justify-between py-3">
            <li className="text-sm tracking-wide font-medium">{details.highlights}</li>
            <IconFactory iconName={IconFactoryTypes.ARROW_FORWARD} iconSize={IconFactorySizes.smaller} />
          </ul>
          <hr className="bottom-1 h-0 border-t-[0.4px] border-gray-500" />
        </div>
      ))}
    </>
  );
}

MoreDetailsPage.propTypes = {
  moreDetails: PropTypes.arrayOf(
    PropTypes.shape({
      highlights: PropTypes.string.isRequired,
      desciption: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
};

export { MoreDetailsPage };
