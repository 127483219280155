import React from 'react';
import { RegistrationForm } from './elements/formComponent';
import { FormImgComponent } from './elements/formImgComponent';
import { SignUpPage } from './elements/SignUpPage';

const headerImg = '/originalImgs/removedBgImgs/header_img.png';

const waitListStyle = {
  Container: 'w-full h-full content-center bg-black lg:flex lg:justify-center lg:items-center',
  LeftPart: 'register-container w-full sm:w-full lg:w-1/2',
  FormContainer: 'mx-auto flex w-3/4 flex-col justify-evenly gap-6 pb-2 text-center text-[#908D90] sm:w-3/5 lg:w-4/5 xl:w-3/5',
  RightPart: 'animation-container lg:w-1/2 max-lg:hidden lg:mx-auto',
};

function RegisterPage() {
  return (
    <div className={waitListStyle.Container}>
      {/* left side page */}
      <div className={waitListStyle.LeftPart}>
        <div className="py-2">
          <img
            src={headerImg}
            alt="Storymode Logo"
            className="mx-auto w-24"
          />
        </div>

        <div className={waitListStyle.FormContainer}>
          <SignUpPage />
          <RegistrationForm />
          <div className="text-sm">
            Already have an account?{' '}
            <a href="/" className="font-semibold underline">
              Log In
            </a>
          </div>
          <small>© 2024 Storymode</small>
        </div>
      </div>

      {/* right side div */}
      <div className={waitListStyle.RightPart}>
        <FormImgComponent />
      </div>
    </div>
  );
}

export { RegisterPage };
