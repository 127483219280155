// config.prod.js
// const BASE_URL = 'http://192.168.29.114:8000';
const BASE_URL = 'https://story-mode-backend.vercel.app';

const prodConfig = {
  LANDING_POPULARCITIES: `${BASE_URL}/mainPage/popularLocation`,
  LANDING_CURATEDSTORIES: `${BASE_URL}/details/stories`,
};

export default prodConfig;
