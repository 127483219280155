/* eslint-disable no-unused-vars */
import React from 'react';
import { PageHeading, PageSubHeading } from 'commons/PageElements';
import HorizontalCardContainer from 'commons/CardsContainer/HorizontalCardContainer';
import useAxios from 'axios-hooks';
import { API_ENDPOINTS } from 'configs/APIEndpoints/index';
import { APP_ROUTES } from 'configs/AppRoutes';
import { DetailedViewCard } from 'commons/Cards/largeCards/DetailedViewCard';

function CuratedStories() {
  const [{ data: curatedData, error: curationError, loading: isLoadingCurations }] = useAxios({
    url: API_ENDPOINTS.LANDING_CURATEDSTORIES,
    method: 'POST',
    data: {
      storyIds: [],
      isCurated: true,
    },
  });
  const [{ data: indiaStoryData, error: indiaError, loading: isLoadingStoriesIndia }] = useAxios({
    url: API_ENDPOINTS.LANDING_CURATEDSTORIES,
    method: 'POST',
    data: {
      storyIds: [],
      country: 'India',
    },
  });
  if (curationError && indiaError) return <div>error</div>;

  // Convert curatedData object to an array of stories
  const curatedStories = curatedData ? Object.values(curatedData) : [];
  const indiaStories = indiaStoryData ? Object.values(indiaStoryData) : [];

  return (
    <>
      <PageHeading type="small">
        Curations in Focus
      </PageHeading>
      <PageSubHeading>Join the Journey with Story Mode</PageSubHeading>
      <HorizontalCardContainer>
        {
            curatedStories.map((data, i) => (
              <DetailedViewCard
                key={i}
                imgURL={data.imgURL}
                title={data.title}
                chipText={data.storyType}
                rating={data.rating}
                ratingCount={data.ratingCount}
                price={data.price}
                navigateTo={APP_ROUTES.APP.SUB_PAGES.CATALOGPAGES.DETAILS_PAGE}
              />
            ))
        }
      </HorizontalCardContainer>

      <PageHeading type="small">
        India&apos;s ❤️, Eternal spots
      </PageHeading>
      <HorizontalCardContainer>
        {
            indiaStories.map((data, i) => (
              <DetailedViewCard
                key={i}
                imgURL={data.imgURL}
                title={data.title}
                chipText={data.storyType}
                rating={data.rating}
                ratingCount={data.ratingCount}
                price={data.price}
                navigateTo={APP_ROUTES.APP.SUB_PAGES.CATALOGPAGES.DETAILS_PAGE}
              />
            ))
        }
      </HorizontalCardContainer>
    </>
  );
}

export default CuratedStories;
