/* eslint-disable max-len */
import React from 'react';
import { Carousel } from 'flowbite-react';
import PropTypes from 'prop-types';

function CarouselSection({ primaryImgs }) {
  return (
    <div className="sm:h-62 xl:h-63 m-2 h-64 2xl:h-64 ">
      <Carousel
        className="text-transparent"
        leftControl="left"
        rightControl="right"
      >
        {primaryImgs.map((imgs) => (
          <img loading="lazy" className="h-64" src={imgs} alt="..." />
        ))}
      </Carousel>
    </div>
  );
}
CarouselSection.propTypes = {
  primaryImgs: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export { CarouselSection };
