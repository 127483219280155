import { object } from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';

const styles = {
  container: {
    width: '100%',
    position: 'relative',
    margin: '0 auto',
    maxWidth: '800px', // Adjust this for a more controlled max size if needed
  },
  imageContainer: {
    width: '100%',
	hight:'100%',
	textAlign:'center',
	alignItems:'center',
	margin:'0 auto',
  },
  image: {
    width: '100%',
	height: '80%',
	borderRadius: '2rem',
	aspectRatio: '1/1',
	objectFit:'cover',
  },
  overlay: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparent black background for better readability
    color: '#fff',
    textAlign: '-webkit-center',
    paddingTop: '10px',
  },
  audioPlayer: {
    width: '90%', // Reduced to give a margin around edges
  },
};
const AudioBar = ({ imageURL, audioURL }) => {
  return (
      <div style={styles.container}>
          {/* Cover Image */}
          <div style={styles.imageContainer}>
              <img src={imageURL} alt="Album Cover" style={styles.image} />
          </div>

          {/* Overlay Audio Controls */}
          <div style={styles.overlay}>
              <ControlledAudio audioUrl={audioURL} />
          </div>
      </div>
  );
};

const ControlledAudio = ({ audioUrl }) => {
  const [currentAudioURL, setCurrentAudioURL] = useState(audioUrl);
  const audioRef = useRef();

  const handlePlay = () => {
    console.log('Playing audio...');
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error("Playback failed:", error);
      });
    }
  };

  useEffect(() => {
    audioRef.current.src = audioUrl;
    audioRef.current.pause();
    setCurrentAudioURL(audioUrl);
    if (audioRef.current) {
      audioRef.current.load(); // Load the new source URL
      handlePlay()
    }
  }, [audioUrl]);

  return (
      <div>
          <audio ref={audioRef} controls preload="metadata" style={styles.audioPlayer}>
              <track
                kind="captions"
                srcLang="en"
                src="path/to/captions.vtt"
                label="English captions"
              />
              <source src={currentAudioURL} type="audio/mpeg" />
              Your browser does not support the audio element.
          </audio>
      </div>
  );
};

export { AudioBar };
