import React from 'react';
import './gettingStarted.css';
import { APP_ROUTES } from 'configs/AppRoutes';

function ReadyDiv() {
  return (
    <div className="content">
      <h1>Ready? Let’s play.</h1>
      <button type="button" className="waitlistButton">
        <a href={APP_ROUTES.LANDING_PAGE.JOIN_WAITLIST}>JOIN WAITLIST</a>
      </button>
    </div>
  );
}

export default ReadyDiv;
