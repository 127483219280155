import React from 'react';
import './primarySection.css';
import 'components/StaticSite/landingPage.css';
import { APP_ROUTES } from 'configs/AppRoutes';

const navImg = '/originalImgs/removedBgImgs/nav_img.png';

function PrimarySection() {
  return (
    <nav>
      <div className="navDiv">
        <img src={navImg} alt="Story Mode" />
        <div className="navText">
          <h1>Explore your world,</h1>
          <h2>One Landmark at a Time with StoryMode</h2>
          <button type="button" className="waitlistButton">
            <a href={APP_ROUTES.LANDING_PAGE.JOIN_WAITLIST}>JOIN WAITLIST</a>
          </button>
        </div>
      </div>
    </nav>
  );
}

export default PrimarySection;
