/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.Fallback;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  Fallback: PropTypes.element,
  children: PropTypes.node,
};
ErrorBoundary.defaultProps = {
  Fallback: null,
  children: null,
};

export { ErrorBoundary as BaseErrorBoundary };
