import React from 'react';

function SignUpPage() {
  return (
    <div className=" mx-auto">
      <h1 className=" heroHeading pb-3 text-3xl font-bold sm:text-4xl">
        JOIN WAITLIST
      </h1>
      <p className="text-base font-medium text-[#908D90]">
        Enhance the way you experience travel.
      </p>
    </div>
  );
}

export { SignUpPage };
