import React from 'react';

const CitySection = () => (
  <div className="bg-[url('/public/images/JaipurHeritage.jpg')] bg-cover bg-center bg-no-repeat">
    <div className="flex flex-col justify-center items-start p-4 gap-2 bg-black bg-opacity-50 ">
      <h1 className="font-bold text-xl tracking-wider">JAIPUR</h1>
      <p className="text-sm font-semibold">
        Jaipur, Indias Pink City, renowned for its rich history, vibrant culture, and
        architectural grandeur, including the majestic Hawa Mahal and Amber Fort.
      </p>
    </div>
  </div>
);

export { CitySection };
