import React from 'react';
import PropTypes from 'prop-types';
import { IconFactory, IconFactoryColors, IconFactorySizes, IconFactoryTypes } from 'commons/Icons/IconFactory';

function RatingSection({ rating, stars }) {
  return (
    <div className="flex items-center justify-start gap-2">
      {stars === true ? (
        <span>⭐⭐⭐⭐⭐</span>
      ) : (
        <IconFactory iconName={IconFactoryTypes.RATING_STAR} iconSize={IconFactorySizes.smaller} color={IconFactoryColors.GOLDEN} />
      )}
      <span className="text-gray-300">
        {rating}
      </span>
    </div>
  );
}
RatingSection.propTypes = {
  rating: PropTypes.number.isRequired,
  stars: PropTypes.bool.isRequired,
};

export { RatingSection };
